import { FunctionComponent } from "react";

import GalleryComponent from "../ui/custom/gallery_component";

import img_1 from "../../assets/png/dtf_1.jpg";
import img_2 from "../../assets/png/dtf_2.jpg";
import img_3 from "../../assets/png/dtf_3.jpg";
import img_4 from "../../assets/png/dtf_4.jpg";
import img_5 from "../../assets/png/dtf_5.jpg";
import img_6 from "../../assets/png/dtf_6.jpg";

const Gallery: FunctionComponent = () => {
  const images = [img_1, img_2, img_3, img_4, img_5, img_6];
  return (
    <div
      id="gallery"
      className="relative bg-slate-950 w-full flex flex-col p-24 box-border items-start justify-start lg:p-20 md:p-10 sm:p-8 xs:p-8"
    >
      <div className="self-stretch flex flex-col items-center justify-center gap-12">
        <div className="self-stretch flex flex-row items-center justify-center gap-12 lg:gap-8">
          <GalleryComponent images={images} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
