import React, { FunctionComponent } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "src/lib/utils";
import { ArrowRight } from "lucide-react";

const headingVariants = cva(
  "w-full flex flex-col flex-wrap items-start justify-start gap-[12px] text-left text-lg text-black font-text-sm-lineheight-5-font-normal",
  {
    variants: {
      type: {
        heading: "self-stretch flex flex-col items-start justify-start gap-2",
        subheading:
          "relative w-full flex flex-col items-start justify-start text-left text-base text-slate-600 font-normal",
      },
    },
    defaultVariants: {
      type: "heading",
    },
  }
);

interface HeadingProps extends VariantProps<typeof headingVariants> {
  title: string;
  description?: string;
  linkText?: string;
  linkHref?: string;
}

const Heading: FunctionComponent<HeadingProps> = ({
  title,
  description,
  type,
  linkText,
  linkHref,
}) => {
  return (
    <div className={cn(headingVariants({ type }))}>
      <div className="self-stretch flex flex-col items-start justify-start gap-4">
        <div className="relative leading-[20px] font-semibold flex-col items-start w-full flex flex-wrap">
          {title}
        </div>
        {type === "heading" && (
          <div className="self-stretch relative rounded-12xs bg-primary-gradient-to-r shadow-[0px_0px_0px_4px_rgba(255,_255,_255,_0.01)] h-px" />
        )}
      </div>
      {description && (
        <>
          <div className="self-stretch relative text-sm leading-[20px] text-slate-500 flex flex-wrap">
            {description}
          </div>
        </>
      )}
      {linkText && linkHref && (
        <a
          href={linkHref}
          className="cursor-pointer [border:none] p-0 bg-[transparent] rounded-rounded-lg overflow-hidden flex flex-row items-center justify-center gap-1"
        >
          <ArrowRight className="relative w-4 h-4" />
          <div className="relative text-sm leading-[150%] font-medium font-text-sm-font-semibold text-gradient text-left flex-wrap">
            {linkText}
          </div>
        </a>
      )}
    </div>
  );
};

export default Heading;
