import PageHeader from "../ui/custom/page_header";
import { Link as RouterLink } from "react-router-dom";
import FAQ from "../sections/faq";
import { Input } from "../ui/input";
import { Checkbox } from "../ui/checkbox";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import React, { useState, useEffect } from "react";

import location from "../../assets/svg/location.svg";
import phone from "../../assets/svg/phone.svg";
import mail from "../../assets/svg/mail.svg";
import building from "../../assets/svg/building.svg";

import email_sent from "../../assets/svg/email_sent.svg";
import email_error from "../../assets/svg/email_error.svg";
import { useTranslation } from "../../TranslationContext";

const Contact = () => {
  const translations = useTranslation();
  const [nameAndSurname, setNameAndSurname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [note, setNote] = useState("");
  const [consent, setConsent] = useState(false);

  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const getLanguageFromHostname = () => {
    const hostname = window.location.hostname;
    const languageMap: { [key: string]: string } = {
      "dtf.nexprim.com": "en",
      "dtfprint.cz": "cz",
      "dtfprint.at": "de",
      default: "sk",
    };
    return languageMap[hostname] || languageMap["default"];
  };

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    console.log("submit");

    // Check if consent is true
    if (!consent) {
      alert("Consent must be given.");
      return;
    }

    // Required fields
    const requiredFields = [
      { name: "nameAndSurname", value: nameAndSurname },
      { name: "email", value: email },
      { name: "note", value: note },
    ];

    // Check if all required fields are filled
    const unfilledFields = requiredFields.filter((field) => !field.value);

    if (unfilledFields.length > 0) {
      alert(
        `Please fill the following fields: ${unfilledFields
          .map((field) => field.name)
          .join(", ")}`
      );
      return;
    }

    setIsWaitingForResponse(true);
    window.scrollTo(0, 100);

    // If everything is correct, create JSON and print it to console
    const payload = {
      note,
      nameAndSurname,
      company: company ? company : "",
      email,
      phoneNumber,
      consent,
      language: getLanguageFromHostname(),
      emailTitle: translations.contact.emailTitle,
    };

    console.log(payload);

    const url =
      "https://europe-central2-nexprim-5569f.cloudfunctions.net/contact-form";

    console.log(JSON.stringify(payload));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        setIsError(true);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // or response.text() if the response is not a JSON object
      if (data.message === "Email sent") {
        setIsEmailSent(true);
      }
      console.log("Response data:", data);
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      // Set isLoading to false when the API request ends
      // setIsWaitingForResponse(false);
    }
  };

  useEffect(() => {
    if (isWaitingForResponse || isEmailSent || isError) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Allow scrolling
      document.body.style.overflow = "auto";
    }
  }, [isWaitingForResponse, isEmailSent, isError]);

  return (
    <div className="font-outfit">
      {isWaitingForResponse && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-center gap-28 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.contact.emailSending.title}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  <span>{`Práve posielame Vašu správu. `}</span>
                  <span className="font-medium text-gradient">
                    {translations.contact.emailSending.message}
                  </span>
                </div>
              </div>
              <span className="loader-38"> </span>
            </div>
          </div>
        </div>
      )}
      {isEmailSent && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-start gap-28 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.contact.emailSent.title}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  {translations.contact.emailSent.message}
                </div>
              </div>
              <img className="relative w-60 h-60" alt="" src={email_sent} />
              <RouterLink to="/">
                <Button
                  variant="primary"
                  size="default"
                  className="uppercase"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsEmailSent(false);
                    setIsWaitingForResponse(false);
                  }}
                >
                  {translations.contact.emailSent.homeButton}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      )}
      {isError && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-center gap-20 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.contact.error.title}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  {translations.contact.error.message}
                </div>
              </div>
              <img className="relative w-60 h-60" alt="" src={email_error} />
              <div className="flex flex-col items-center justify-center gap-4">
                <div className="flex flex-row items-end justify-center gap-6">
                  <RouterLink
                    to="/contact"
                    style={{ display: "inline-block", padding: 0, margin: 0 }}
                  >
                    <Button
                      variant="primary"
                      size="default"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setIsError(false);
                        setIsEmailSent(false);
                        setIsWaitingForResponse(false);
                      }}
                    >
                      {translations.contact.error.contactButton}
                    </Button>
                  </RouterLink>
                  <Button
                    variant="default"
                    size="default_stretch"
                    type="submit"
                    onClick={() => {
                      setIsError(false);
                      setIsEmailSent(false);
                      handleSubmit();
                    }}
                  >
                    {translations.contact.error.retryButton}
                  </Button>
                </div>
                <div className="relative max-w-[500px] text-base sm:text-sm leading-[150%] text-slate-600">
                  {translations.contact.error.additionalInfo}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <div
          style={{ visibility: isWaitingForResponse ? "hidden" : "visible" }}
        >
          <div className="bg-white h-fit w-full self-stretch flex flex-col">
            <PageHeader
              title={translations.contact.pageHeader.title}
              subtitle={translations.contact.pageHeader.subtitle}
              background="default"
            />
            <FAQ isTitleVisible={false} />
            <div className="self-stretch flex flex-col items-center justify-center pt-10 px-20 gap-20 sm:gap-10 text-4xl md:text-3xl text-livid-900 sm:px-12 md:px-10 xs:px-6 xs:py-8 ">
              <div className="self-stretch flex flex-col items-center justify-center gap-4">
                <div className="self-stretch flex flex-row items-center justify-center relative leading-[150%] font-extrabold">
                  {translations.contact.form.title}
                </div>
                <div className="self-stretch flex flex-row items-center justify-center relative text-xl lg:text-lg sm:text-base leading-[150%] text-slate-600">
                  {translations.contact.form.subtitle}
                </div>
              </div>
              <div className="w-full flex flex-col items-center justify-center max-w-[768px] text-left text-sm text-slate-800">
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    // Assert e.target as an HTMLElement to access tagName
                    const target = e.target as HTMLElement;
                    if (e.key === "Enter" && target.tagName !== "TEXTAREA") {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-full flex flex-row flex-wrap items-center justify-start gap-8 max-w-[768px]">
                      <div className="w-full self-stretch flex flex-row items-center justify-center text-sm font-text-sm-font-semibold">
                        <div className="w-full self-stretch flex flex-row items-center justify-center gap-3 text-slate-500">
                          <div className="w-full  self-stretch flex flex-col items-center justify-center gap-[12px]">
                            <div className="w-full self-stretch flex flex-row items-center justify-center gap-[12px] align-bottom sm:flex-col">
                              <div className="self-stretch flex-1">
                                <Input
                                  type="text"
                                  label={translations.contact.form.nameLabel}
                                  warningText={
                                    translations.contact.form.nameWarning
                                  }
                                  validationRule={/^[\p{L}'-]+ [\p{L}'-]+$/u}
                                  onValueChange={setNameAndSurname}
                                  autoComplete="name"
                                />
                              </div>
                              <div className="self-stretch flex-1">
                                <Input
                                  type="text"
                                  label={translations.contact.form.companyLabel}
                                  optional={true}
                                  warningText={
                                    translations.contact.form.companyWarning
                                  }
                                  onValueChange={setCompany}
                                  autoComplete="organization"
                                />
                              </div>
                            </div>

                            <div className="self-stretch flex flex-row items-start justify-start gap-[12px] sm:flex-col">
                              <div className="self-stretch flex-1">
                                <Input
                                  type="email"
                                  label={translations.contact.form.emailLabel}
                                  warningText={
                                    translations.contact.form.emailWarning
                                  }
                                  validationRule={
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                  }
                                  onValueChange={setEmail}
                                  autoComplete="email"
                                />
                              </div>
                              <div className="self-stretch flex-1">
                                <Input
                                  type="tel"
                                  label={translations.contact.form.phoneLabel}
                                  optional={true}
                                  warningText={
                                    translations.contact.form.phoneWarning
                                  }
                                  validationRule={/^\+?[\d\s]{8,15}$/}
                                  onValueChange={setPhoneNumber}
                                  autoComplete="tel"
                                />
                              </div>
                            </div>
                            <div className="flex justify-center items-center w-full h-72 bg-white">
                              <Textarea
                                label={translations.contact.form.messageLabel}
                                placeholder={
                                  translations.contact.form.messagePlaceholder
                                }
                                onChange={(e) => setNote(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch w-full flex flex-col items-start justify-center text-sm text-red-400 font-text-sm-font-semibold gap-3">
                        <Checkbox
                          labelText={
                            <span>
                              {translations.contact.form.consentText1}{" "}
                              <a
                                href="/privacy-policy"
                                style={{
                                  textDecoration: "underline",
                                  fontWeight: "bold",
                                }}
                              >
                                {translations.contact.form.consentText2}
                              </a>
                              .
                            </span>
                          }
                          onCheckedChange={(checked) =>
                            setConsent(
                              checked !== "indeterminate" ? checked : false
                            )
                          } // Add this line
                        />
                        <Button
                          variant="default"
                          size="default_stretch"
                          type="submit"
                        >
                          {translations.contact.form.submitButton}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="self-stretch flex flex-row items-center justify-center py-5 px-10 lg:px-0 text-base text-livid-900">
                <div className="flex flex-row items-start gap-4 w-full lg:grid lg:grid-cols-2 lg:grid-rows-2 sm:flex sm:flex-col sm:items-center lg:gap-20 md:gap-10 justify-between py-0 px-0 box-border max-w-[1280px]">
                  <div className="self-stretch flex-1 overflow-hidden flex flex-col items-center justify-start gap-[16px]">
                    <img className="relative w-12 h-12" alt="" src={building} />
                    <div className="self-stretch flex flex-col items-center justify-start gap-[4px] text-center">
                      <b className="relative text-xl leading-[150%]">
                        {translations.contact.companyInfo.title}
                      </b>
                      <div className="self-stretch relative leading-[150%] text-slate-500">
                        <p className="m-0">
                          {translations.contact.companyInfo.ico}
                        </p>
                        <p className="m-0">
                          {translations.contact.companyInfo.dic}
                        </p>
                        <p className="m-0">
                          {translations.contact.companyInfo.icDph}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 overflow-hidden flex flex-col items-center justify-start gap-[16px]">
                    <img className="relative w-12 h-12" alt="" src={location} />
                    <div className="self-stretch flex flex-col items-center justify-start gap-[4px] text-center">
                      <b className="relative text-xl leading-[150%]">
                        {translations.contact.visitUs.title}
                      </b>
                      <div className="self-stretch relative leading-[150%] text-slate-500">
                        <p className="m-0">
                          {translations.contact.visitUs.address1}
                        </p>
                        <p className="m-0">
                          {translations.contact.visitUs.address2}
                        </p>
                      </div>
                      <div className="relative leading-[150%] font-semibold text-gradient">
                        <a
                          href="https://maps.app.goo.gl/nrbfaUsNsKpbrYaY9"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translations.contact.visitUs.mapLink}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 overflow-hidden flex flex-col items-center justify-start gap-[16px]">
                    <img className="relative w-12 h-12" alt="" src={mail} />
                    <div className="self-stretch flex flex-col items-center justify-start gap-[4px] text-center">
                      <b className="relative text-xl leading-[150%]">
                        {translations.contact.writeUs.title}
                      </b>
                      <div className="self-stretch relative leading-[150%] text-slate-500">
                        {translations.contact.writeUs.response}
                      </div>
                      <div className="relative leading-[150%] font-semibold text-gradient">
                        <a href="mailto:info@nexprim.com">
                          {translations.contact.writeUs.email}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 overflow-hidden flex flex-col items-center justify-start gap-[16px] text-xl">
                    <img className="relative w-12 h-12" alt="" src={phone} />
                    <div className="self-stretch flex flex-col items-center justify-center gap-[4px] text-center">
                      <b className="relative leading-[150%]">
                        {translations.contact.callUs.title}
                      </b>
                      <div className="self-stretch relative text-base leading-[150%] text-slate-500">
                        {translations.contact.callUs.hours}
                      </div>
                      <b className="relative text-sm leading-[150%] text-gradient">
                        <a href="tel:+421915558916">
                          {translations.contact.callUs.phone}
                        </a>
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Contact;
