import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import PageHeader from "../ui/custom/page_header";
import FileUpload from "../ui/custom/file_upload";
import { Input } from "../ui/input";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Checkbox } from "../ui/checkbox";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import Heading from "../ui/custom/heading";
import Cookies from "js-cookie";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { AlertCircle } from "lucide-react";
import email_sent from "../../assets/svg/email_sent.svg";
import email_error from "../../assets/svg/email_error.svg";
import info_outline from "../../assets/svg/info_outline.svg";
import google_pay from "../../assets/svg/logo_google_pay.svg";
import apple_pay from "../../assets/svg/logo_apple_pay.svg";
import visa from "../../assets/svg/logo_visa.svg";
import maestro from "../../assets/svg/logo_maestro.svg";
import mastercard from "../../assets/svg/logo_mastercard.svg";
import { useTranslation } from "../../TranslationContext";
import { Language } from "@mui/icons-material";

declare global {
  interface Window {
    gtag_report_conversion: (url?: string) => void;
  }
}

const Order = () => {
  const translations = useTranslation();
  const graphicRef = useRef<HTMLDivElement | null>(null);
  const invoiceRef = useRef<HTMLDivElement | null>(null);
  const deliveryRef = useRef<HTMLDivElement | null>(null);
  const dateRef = useRef<HTMLDivElement | null>(null);
  const consentRef = useRef<HTMLDivElement | null>(null);

  const [length, setLength] = useState("0.00");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [weTransferLink, setWeTransferLink] = useState("");

  const [note, setNote] = useState("");
  const [needHelpWithGraphics, setNeedHelpWithGraphics] = useState(false);

  const [isCompany, setIsCompany] = useState(false);
  const [isVatPayer, setIsVatPayer] = useState(false);
  const [nameAndSurname, setNameAndSurname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [crn, setCRN] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [vat, setVat] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("191");
  const [sameDeliveryAddress, setSameDeliveryAddress] = useState(true);

  const [deliveryNameAndSurname, setDeliveryNameAndSurname] = useState("");
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [deliveryEmail, setDeliveryEmail] = useState("");
  const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useState("");
  const [deliveryStreet, setDeliveryStreet] = useState("");
  const [deliveryStreetNumber, setDeliveryStreetNumber] = useState("");
  const [deliveryZip, setDeliveryZip] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryCountry, setDeliveryCountry] = useState("");

  const [deliveryCost, setDeliveryCost] = useState(4);
  const [deliveryPrice, setDeliveryPrice] = useState(4);
  const [deliveryOption, setDeliveryOption] = useState("dpd");
  const [paymentOption, setPaymentOption] = useState("proforma");
  const [dateOption, setDateOption] = useState<string>("asap");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [lastValue, setLastValue] = React.useState<string | null>(null);
  const [lastDateValue, setLastDateValue] = React.useState<string | null>(null);
  const [totalPrice, setTotalPrice] = useState<string>("0.00");
  const [pricePerMeter, setPricePerMeter] = useState(0);
  const [consent, setConsent] = useState(false);

  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isError, setIsError] = useState(false);

  let dtfLengthRegex = new RegExp(
    /^(0\.(?:2[0-9]?|[3-9]\d?)|[1-9]\d?(?:\.\d{1,2})?|1\d{2}(?:\.\d{1,2})?|200(?:\.0{1,2})?)$/
  );
  let weTransferRegex = new RegExp(/^$|^https:\/\/we\.tl\/t-[a-zA-Z0-9]+$/);
  let nameRegex = new RegExp(/^$|^[\p{L}'-]+ [\p{L}'-]+$/u);
  let companyRegex = new RegExp(/^.{0,100}$/);
  let emailRegex = new RegExp(/^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  let phoneNumberRegex = new RegExp(/^$|^\+?[\d\s]{8,15}$/);
  let crnRegex = new RegExp(/^$|^\d{8}$/);
  let taxRegex = new RegExp(/^$|^\d{8,10}$/);
  let vatRegex = new RegExp(/^[A-Z]{2}\d{8,10}$/);
  let streetRegex = new RegExp(/^.{0,25}$/);
  let streetNumberRegex = new RegExp(/^.{0,6}$/);
  let zipRegex = new RegExp(/^$|^\d{5}$|^\d{3}\s\d{2}$|^\d{2}\s\d{3}$|^\d{4}$/);
  let cityRegex = new RegExp(/^.{0,25}$/);

  const [islengthVerified, setIsLengthVerified] = useState(true);
  const [isFileVerified, setIsFileVerified] = useState(true);
  const [isWeTransferLinkVerified, setIsWeTransferLinkVerified] =
    useState(true);
  const [isFileSubmited, setIsFileSubmited] = useState(true);

  const [isNameVerified, setIsNameVerified] = useState(true);
  const [isCompanyVerified, setIsCompanyVerified] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isNumberVerified, setIsNumberVerified] = useState(true);
  const [isStreetVerified, setIsStreetVerified] = useState(true);
  const [isStreetNumberVerified, setIsStreetNumberVerified] = useState(true);
  const [isZipVerified, setIsZipVerified] = useState(true);
  const [isCityVerified, setIsCityVerified] = useState(true);

  const [isCrnVerified, setIsCrnVerified] = useState(true);
  const [isTaxVerified, setIsTaxVerified] = useState(true);
  const [isVatVerified, setIsVatVerified] = useState(true);

  const [isDeliveryNameVerified, setIsDeliveryNameVerified] = useState(true);
  const [isDeliveryEmailVerified, setIsDeliveryEmailVerified] = useState(true);
  const [isDeliveryNumberVerified, setIsDeliveryNumberVerified] =
    useState(true);
  const [isDeliveryStreetVerified, setIsDeliveryStreetVerified] =
    useState(true);
  const [isDeliveryStreetNumberVerified, setIsDeliveryStreetNumberVerified] =
    useState(true);
  const [isDeliveryZipVerified, setIsDeliveryZipVerified] = useState(true);
  const [isDeliveryCityVerified, setIsDeliveryCityVerified] = useState(true);

  const [consentAlert, setConsentAlert] = useState(false);
  const [graphicAlert, setGraphicAlert] = useState(false);
  const [invoiceAlert, setInvoiceAlert] = useState(false);
  const [companyAlert, setCompanyAlert] = useState(false);
  const [deliveryAlert, setDeliveryAlert] = useState(false);
  const [dateAlert, setDateAlert] = useState(false);
  const [alertTimer, setAlertTimer] = useState(false);

  const validateAlwaysRequiredFields = () => {
    setIsLengthVerified(Boolean(length && dtfLengthRegex.test(length)));
    setIsNameVerified(
      Boolean(nameAndSurname && nameRegex.test(nameAndSurname))
    );
    setIsEmailVerified(Boolean(email && emailRegex.test(email)));
    setIsNumberVerified(
      Boolean(phoneNumber && phoneNumberRegex.test(phoneNumber))
    );
    setIsStreetVerified(Boolean(street && streetRegex.test(street)));
    setIsStreetNumberVerified(
      Boolean(streetNumber && streetNumberRegex.test(streetNumber))
    );
    setIsZipVerified(Boolean(zip && zipRegex.test(zip)));
    setIsCityVerified(Boolean(city && cityRegex.test(city)));
  };

  const validateCompanyRequiredFields = () => {
    if (isCompany) {
      setIsCompanyVerified(Boolean(company && companyRegex.test(company)));
      setIsCrnVerified(Boolean(crn && crnRegex.test(crn)));
      setIsTaxVerified(Boolean(taxNumber && taxRegex.test(taxNumber)));
      setIsVatVerified(true);
    }
  };

  const validateFileSubmit = () => {
    setIsFileSubmited(
      Boolean(selectedFile) ||
        (Boolean(weTransferLink) && weTransferRegex.test(weTransferLink))
    );
  };

  const validateDeliveryFields = () => {
    if (!sameDeliveryAddress) {
      setIsDeliveryNameVerified(
        Boolean(
          deliveryNameAndSurname && nameRegex.test(deliveryNameAndSurname)
        )
      );
      setIsDeliveryEmailVerified(
        Boolean(deliveryEmail && emailRegex.test(deliveryEmail))
      );
      setIsDeliveryNumberVerified(
        Boolean(
          deliveryPhoneNumber && phoneNumberRegex.test(deliveryPhoneNumber)
        )
      );
      setIsDeliveryStreetVerified(
        Boolean(deliveryStreet && streetRegex.test(deliveryStreet))
      );
      setIsDeliveryStreetNumberVerified(
        Boolean(
          deliveryStreetNumber && streetNumberRegex.test(deliveryStreetNumber)
        )
      );
      setIsDeliveryZipVerified(
        Boolean(deliveryZip && zipRegex.test(deliveryZip))
      );
      setIsDeliveryCityVerified(
        Boolean(deliveryCity && cityRegex.test(deliveryCity))
      );
    }
  };

  const handleFileChange = async (file: File | null) => {
    if (file === null) {
      console.log("No file selected.");
      return;
    }

    setSelectedFile(file);

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async (event) => {
      const arrayBuffer = (event.target as FileReader).result as ArrayBuffer;

      // Load the PDF document
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the number of pages
      const numPages = pdfDoc.getPageCount();

      // Loop through all pages
      for (let i = 0; i < numPages; i++) {
        const page = pdfDoc.getPage(i);

        // Get the size of the current page in points
        const { width, height } = page.getSize();

        // Convert points to millimeters
        const widthInMillimeters = width * (25.4 / 72);
        const heightInMillimeters = height * (25.4 / 72);

        console.log(
          `Page ${i + 1} size: ${widthInMillimeters.toFixed(
            2
          )}mm x ${heightInMillimeters.toFixed(2)}mm`
        );
      }

      // Here you can set state variables or perform other actions based on the page sizes
    };
  };

  const getLanguageFromHostname = () => {
    const hostname = window.location.hostname;
    const languageMap: { [key: string]: string } = {
      "dtf.nexprim.com": "en",
      "dtfprint.cz": "cz",
      "dtfprint.at": "de",
      default: "sk",
    };
    return languageMap[hostname] || languageMap["default"];
  };

  const getHostname = () => {
    const hostname = window.location.hostname;
    return hostname;
  };

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    //console.log("submit");

    validateAlwaysRequiredFields();
    validateFileSubmit();
    validateCompanyRequiredFields();
    validateDeliveryFields();

    // Check if consent is true
    if (!consent) {
      setConsentAlert(true);
    } else {
      setConsentAlert(false);
    }

    if (!isFileSubmited || !Boolean(length && dtfLengthRegex.test(length))) {
      setGraphicAlert(true);
    } else {
      setGraphicAlert(false);
    }

    if (
      !Boolean(nameAndSurname && nameRegex.test(nameAndSurname)) ||
      !Boolean(email && emailRegex.test(email)) ||
      !Boolean(phoneNumber && phoneNumberRegex.test(phoneNumber)) ||
      !Boolean(street && streetRegex.test(street)) ||
      !Boolean(streetNumber && streetNumberRegex.test(streetNumber)) ||
      !Boolean(zip && zipRegex.test(zip)) ||
      !Boolean(city && cityRegex.test(city))
    ) {
      setInvoiceAlert(true);
    } else {
      setInvoiceAlert(false);
    }

    if (
      isCompany &&
      (!Boolean(company && companyRegex.test(company)) ||
        !Boolean(crn && crnRegex.test(crn)) ||
        !Boolean(taxNumber && taxRegex.test(taxNumber)))
    ) {
      setCompanyAlert(true);
    } else {
      setCompanyAlert(false);
    }

    if (
      !sameDeliveryAddress &&
      (!Boolean(
        deliveryNameAndSurname && nameRegex.test(deliveryNameAndSurname)
      ) ||
        !Boolean(deliveryEmail && emailRegex.test(deliveryEmail)) ||
        !Boolean(
          deliveryPhoneNumber && phoneNumberRegex.test(deliveryPhoneNumber)
        ) ||
        !Boolean(
          deliveryStreetNumber && streetNumberRegex.test(deliveryStreetNumber)
        ) ||
        !Boolean(streetNumber && streetNumberRegex.test(streetNumber)) ||
        !Boolean(deliveryZip && zipRegex.test(deliveryZip)) ||
        !Boolean(deliveryCity && cityRegex.test(deliveryCity)))
    ) {
      setDeliveryAlert(true);
    } else {
      setDeliveryAlert(false);
    }

    if (dateOption === "date" && !selectedDate) {
      setDateAlert(true);
    } else {
      setDateAlert(false);
    }

    setAlertTimer(true);

    if (!isFileSubmited || !Boolean(length && dtfLengthRegex.test(length))) {
      graphicRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (
      !Boolean(nameAndSurname && nameRegex.test(nameAndSurname)) ||
      !Boolean(email && emailRegex.test(email)) ||
      !Boolean(phoneNumber && phoneNumberRegex.test(phoneNumber)) ||
      !Boolean(street && streetRegex.test(street)) ||
      !Boolean(streetNumber && streetNumberRegex.test(streetNumber)) ||
      !Boolean(zip && zipRegex.test(zip)) ||
      !Boolean(city && cityRegex.test(city))
    ) {
      invoiceRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (
      isCompany &&
      (!Boolean(company && companyRegex.test(company)) ||
        !Boolean(crn && crnRegex.test(crn)) ||
        !Boolean(taxNumber && taxRegex.test(taxNumber)))
    ) {
      invoiceRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (
      !sameDeliveryAddress &&
      (!Boolean(
        deliveryNameAndSurname && nameRegex.test(deliveryNameAndSurname)
      ) ||
        !Boolean(deliveryEmail && emailRegex.test(deliveryEmail)) ||
        !Boolean(
          deliveryPhoneNumber && phoneNumberRegex.test(deliveryPhoneNumber)
        ) ||
        !Boolean(
          deliveryStreetNumber && streetNumberRegex.test(deliveryStreetNumber)
        ) ||
        !Boolean(streetNumber && streetNumberRegex.test(streetNumber)) ||
        !Boolean(deliveryZip && zipRegex.test(deliveryZip)) ||
        !Boolean(deliveryCity && cityRegex.test(deliveryCity)))
    ) {
      deliveryRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (dateOption === "date" && !selectedDate) {
      dateRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (!consent) {
      consentRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    setIsWaitingForResponse(true);
    window.scrollTo(0, 100);

    // Convert selectedFile to Base64
    let selectedFileBase64 = "";
    if (selectedFile) {
      selectedFileBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(selectedFile);
      });
    }

    // Fetch the IP address
    let ipAddress = "";
    try {
      const ipResponse = await fetch("https://api.ipify.org?format=json");
      if (ipResponse.ok) {
        const ipData = await ipResponse.json();
        ipAddress = ipData.ip;
        console.log(ipAddress);
      } else {
        console.error("Failed to fetch IP address");
      }
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }

    const payload = {
      city,
      company: isCompany ? company : "undefined",
      consent,
      country,
      crn: isCompany ? crn : "undefined",
      dateOption,
      deliveryCity: !sameDeliveryAddress ? deliveryCity : city,
      deliveryCompany: !sameDeliveryAddress
        ? deliveryCompany
        : isCompany
        ? company
        : "undefined",
      deliveryCountry: !sameDeliveryAddress
        ? deliveryCountry || country
        : country,
      deliveryEmail: !sameDeliveryAddress ? deliveryEmail : email,
      deliveryNameAndSurname: !sameDeliveryAddress
        ? deliveryNameAndSurname
        : nameAndSurname,
      deliveryOption:
        deliveryOption === "dpd"
          ? translations.order.payload.dpd
          : translations.order.payload.pickup,
      deliveryPhoneNumber: !sameDeliveryAddress
        ? deliveryPhoneNumber
        : phoneNumber,
      deliveryPrice,
      deliveryStreet: !sameDeliveryAddress ? deliveryStreet : street,
      deliveryStreetNumber: !sameDeliveryAddress
        ? deliveryStreetNumber
        : streetNumber,
      deliveryType: deliveryOption === "dpd" ? "courier" : "personal",
      deliveryZip: !sameDeliveryAddress ? deliveryZip : zip,
      domainLanguage: getLanguageFromHostname(),
      dtfPrice: (pricePerMeter * parseFloat(length)).toFixed(2),
      email,
      emailTitle: translations.order.payload.emailTitle,
      hostname: getHostname(),
      ipAddress,
      invoiceDelivery: translations.order.payload.invoiceDelivery,
      invoiceDeliveryUnit: translations.order.payload.invoiceDeliveryUnit,
      invoiceName: translations.order.payload.invoiceName,
      invoiceProduct: translations.order.payload.invoiceProduct,
      invoiceProductUnit: translations.order.payload.invoiceProductUnit,
      isVatPayer,
      language: getLanguageFromHostname(),
      length,
      nameAndSurname,
      needHelpWithGraphics:
        needHelpWithGraphics === true
          ? translations.order.payload.yes
          : translations.order.payload.no,
      note,
      orderType: "dtf",
      paymentOption:
        paymentOption === "proforma"
          ? translations.order.payload.proforma
          : paymentOption === "cardpay"
          ? translations.order.payload.cardpay
          : paymentOption === "tatrapayplus"
          ? translations.order.payload.tatrapayplus
          : "undefined",
      paymentType:
        paymentOption === "proforma"
          ? "transfer"
          : paymentOption === "cardpay"
          ? "card"
          : paymentOption === "tatrapayplus"
          ? "other"
          : "undefined",
      phoneNumber,
      pricePerMeter,
      productPrice: (pricePerMeter * parseFloat(length)).toFixed(2),
      quantity: length,
      selectedDate:
        dateOption === "date"
          ? selectedDate
            ? selectedDate.toLocaleDateString("sk-SK")
            : null
          : translations.order.payload.asap,
      selectedFilename: selectedFile
        ? selectedFile.name
        : translations.order.payload.graphics,
      street,
      streetNumber,
      taxNumber: isCompany ? taxNumber : "undefined",
      totalPrice,
      totalPriceWithVat: (
        (pricePerMeter * parseFloat(length) + deliveryPrice) *
        1.2
      ).toFixed(2),
      unitPrice: pricePerMeter,
      vat: isCompany ? vat : "",
      vatPrice: (
        (pricePerMeter * parseFloat(length) + deliveryPrice) *
        0.2
      ).toFixed(2),
      weTransferLink,
      zip,
      selectedFile: selectedFileBase64,
    };

    const url =
      "https://europe-central2-nexprim-5569f.cloudfunctions.net/order";

    //console.log(JSON.stringify(payload));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      console.log(response);

      if (!response.ok) {
        setIsError(true);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // or response.text() if the response is not a JSON object
      if (data.message === "Email sent") {
        setIsEmailSent(true);
        const cookieValue = Cookies.get("cookies-preference");
        let marketingConsent = false;

        if (cookieValue) {
          try {
            const preferences = JSON.parse(cookieValue);
            marketingConsent = preferences.marketing === true; // Note the strict equality to boolean true
          } catch (error) {
            console.error("Error parsing cookies-preference:", error);
          }
        }
        if (marketingConsent) {
          window.gtag_report_conversion("https://dtfprint.sk/");
        }
      }
      if (paymentOption === "cardpay" && data.payment_url) {
        // If paymentOption is cardpay and payment_url is present in the response, redirect to that URL
        window.location.href = data.payment_url;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsError(true);
    } finally {
    }
  };

  const [isDivVisible, setIsDivVisible] = useState(true);

  useEffect(() => {
    if (alertTimer) {
      const timer = setTimeout(() => {
        // Reset all alerts
        setConsentAlert(false);
        setGraphicAlert(false);
        setInvoiceAlert(false);
        setCompanyAlert(false);
        setDeliveryAlert(false);
        setDateAlert(false);

        // Reset alertTimer
        setAlertTimer(false);
      }, 5000);

      return () => clearTimeout(timer); // This will clear the timer if the component unmounts before the timer finishes
    }
  }, [alertTimer]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Set the visibility state to the opposite of the intersection status
        setIsDivVisible(
          !entry.isIntersecting &&
            window.pageYOffset < (entry.target as HTMLElement).offsetTop
        );
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const target = document.getElementById("bottom_separator");
    if (target) {
      observer.observe(target);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  useEffect(() => {
    const updateScrollPosition = () => {};
    window.addEventListener("scroll", updateScrollPosition);
    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);

  useEffect(() => {
    setPricePerMeter(0);
    setTotalPrice("0.00");
    let pricePerMeter = 0;
    let totalPrice = 0;
    let lengthNumber = parseFloat(length);
    if (deliveryCountry) {
      if (deliveryCountry === "191") {
        setDeliveryCost(4);
      } else if (
        deliveryCountry === "57" ||
        deliveryCountry === "14" ||
        deliveryCountry === "172" ||
        deliveryCountry === "99"
      ) {
        setDeliveryCost(7);
      } else {
        setDeliveryCost(9);
      }
    } else {
      if (country === "191") {
        setDeliveryCost(4);
      } else if (
        country === "57" ||
        country === "14" ||
        country === "172" ||
        country === "99"
      ) {
        setDeliveryCost(7);
      } else {
        setDeliveryCost(9);
      }
    }
    setDeliveryPrice(
      (deliveryOption === "dpd" && lengthNumber > 999) ||
        deliveryOption === "pickup"
        ? 0
        : deliveryCost
    );

    if (lengthNumber > 0.19 && lengthNumber < 200.01) {
      const pricingTable = [
        { min: 0.2, max: 0.49, price: 25 },
        { min: 0.5, max: 0.99, price: 20 },
        { min: 1, max: 1.49, price: 18 },
        { min: 1.5, max: 1.99, price: 17.5 },
        { min: 2, max: 3.99, price: 17 },
        { min: 4, max: 5.99, price: 16 },
        { min: 6, max: 8.99, price: 15 },
        { min: 9, max: 12.99, price: 14 },
        { min: 13, max: 19.99, price: 13 },
        { min: 20, max: 29.99, price: 12 },
        { min: 30, max: 99.99, price: 11 },
        { min: 100, max: 200, price: 10 },
        { min: 200.01, max: 201, price: 8 },
      ]; // Get the pricing array for the selected badge size

      // Find the two price points that numberOfPieces falls between
      let lowerPricePoint, upperPricePoint;
      for (let i = 0; i < pricingTable.length; i++) {
        if (
          lengthNumber >= pricingTable[i].min &&
          lengthNumber <= pricingTable[i].max
        ) {
          lowerPricePoint = pricingTable[i];
          upperPricePoint = pricingTable[i + 1];
          break;
        }
      }

      // Perform linear interpolation between the two price points
      if (lowerPricePoint && upperPricePoint) {
        const range = lowerPricePoint.max - lowerPricePoint.min;
        const progress = lowerPricePoint.max - lengthNumber;
        const priceDifference = lowerPricePoint.price - upperPricePoint.price;
        pricePerMeter =
          Math.ceil(
            (upperPricePoint.price + (priceDifference / range) * progress) * 100
          ) / 100;

        totalPrice = Math.ceil(pricePerMeter * lengthNumber * 100) / 100;
        const delPrice =
          (deliveryOption === "dpd" && lengthNumber > 999) ||
          deliveryOption === "pickup"
            ? 0
            : deliveryCost;
        totalPrice += delPrice;
        const totalPriceFormatted = Number(totalPrice).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        setPricePerMeter(Number(pricePerMeter.toFixed(3)));
        setTotalPrice(totalPriceFormatted);
        // setTotalPrice(parseFloat(totalPrice.toFixed(2)));
        // setPiecePrice(parseFloat(pricePerMeter.toFixed(2)));
      } else {
        setPricePerMeter(Number(pricePerMeter.toFixed(3)));
        setDeliveryPrice(deliveryOption === "pickup" ? 0 : deliveryCost);
      }
      // Perform calculations
    }
  }, [length, deliveryOption, deliveryCountry, country, deliveryCost]);

  useEffect(() => {
    if (isWaitingForResponse || isEmailSent || isError) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Allow scrolling
      document.body.style.overflow = "auto";
    }
  }, [isWaitingForResponse, isEmailSent, isError]);

  return (
    <div className="font-outfit">
      {(consentAlert ||
        graphicAlert ||
        invoiceAlert ||
        companyAlert ||
        deliveryAlert ||
        dateAlert) && (
        <div className="fixed top-0 left-1/2 transform -translate-x-1/2 z-50 inline-flex flex-col pt-3 gap-3 items-center justify-start">
          {graphicAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>
                  {translations.order.alerts.graphic.title}
                </AlertTitle>
                <AlertDescription>
                  {translations.order.alerts.graphic.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
          {invoiceAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>
                  {translations.order.alerts.invoice.title}
                </AlertTitle>
                <AlertDescription>
                  {translations.order.alerts.invoice.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
          {companyAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>
                  {translations.order.alerts.company.title}
                </AlertTitle>
                <AlertDescription>
                  {translations.order.alerts.company.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
          {deliveryAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>
                  {translations.order.alerts.delivery.title}
                </AlertTitle>
                <AlertDescription>
                  {translations.order.alerts.delivery.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
          {dateAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>{translations.order.alerts.date.title}</AlertTitle>
                <AlertDescription>
                  {translations.order.alerts.date.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
          {consentAlert && (
            <div className="shadow-md w-[500px] sm:w-screen">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>
                  {translations.order.alerts.consent.title}
                </AlertTitle>
                <AlertDescription>
                  {translations.order.alerts.consent.description}
                </AlertDescription>
              </Alert>
            </div>
          )}
        </div>
      )}

      {isWaitingForResponse && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-center gap-28 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.order.processingOrder.title}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  <span>{translations.order.processingOrder.description}</span>
                  <span className="font-medium text-gradient">
                    {translations.order.processingOrder.warning}
                  </span>
                </div>
              </div>
              <span className="loader-38"> </span>
            </div>
          </div>
        </div>
      )}
      {isEmailSent && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-start gap-28 sm:gap-10 py-20">
              <div className="w-full flex flex-col gap-10 items-center justify-center">
                <div className="flex flex-col items-center justify-center gap-[10px] max-w-4xl">
                  <div className="relative leading-[150%] font-semibold">
                    {translations.order.orderSent.title}
                  </div>
                  <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                    {translations.order.orderSent.description}
                  </div>
                </div>
                <div className="w-auto relative rounded-lg bg-light-gradient flex flex-row items-center justify-center px-6 py-5 box-border gap-4 text-left text-lg sm:text-base text-slate-900 font-normal">
                  <img
                    className="w-8 relative h-8 overflow-hidden shrink-0"
                    alt="nexprim-info"
                    src={info_outline}
                  />
                  <div className="flex-1 relative leading-[150%] whitespace-pre-wrap">
                    {translations.order.orderSent.emailNotice.text}{" "}
                    <span className="font-semibold text-gradient">
                      {translations.order.orderSent.emailNotice.highlight}
                    </span>{" "}
                    {translations.order.orderSent.emailNotice.textAfter}
                  </div>
                </div>
              </div>
              <img className="relative w-60 h-60" alt="" src={email_sent} />
              <RouterLink to="/">
                <Button
                  variant="primary"
                  size="default"
                  className="uppercase"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsEmailSent(false);
                    setIsWaitingForResponse(false);
                  }}
                >
                  {translations.order.orderSent.homeButton}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      )}
      {isError && (
        <div className="fixed inset-0 flex items-center justify-center bg-white text-center">
          <div className="self-stretch flex-1 flex flex-col items-center justify-center text-4xl sm:text-2xl py-18 px-10">
            <div className="flex flex-col items-center justify-center gap-20 sm:gap-10 py-20">
              <div className="flex flex-col items-center justify-center gap-[10px]">
                <div className="relative leading-[150%] font-semibold">
                  {translations.order.orderError.title}
                </div>
                <div className="relative text-xl sm:text-base leading-[150%] text-slate-600">
                  {translations.order.orderError.description}
                </div>
              </div>
              <img className="relative w-60 h-60" alt="" src={email_error} />
              <div className="flex flex-col items-center justify-center gap-4">
                <div className="flex flex-row items-end justify-center gap-6">
                  <RouterLink
                    to="/contact"
                    style={{ display: "inline-block", padding: 0, margin: 0 }}
                  >
                    <Button
                      variant="primary"
                      size="default"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setIsError(false);
                        setIsEmailSent(false);
                        setIsWaitingForResponse(false);
                      }}
                    >
                      {translations.order.orderError.contactButton}
                    </Button>
                  </RouterLink>
                  <Button
                    variant="default"
                    size="default_stretch"
                    type="submit"
                    onClick={() => {
                      setIsError(false);
                      setIsEmailSent(false);
                      handleSubmit();
                    }}
                  >
                    {translations.order.orderError.retryButton}
                  </Button>
                </div>
                <div className="relative max-w-[500px] text-base sm:text-sm leading-[150%] text-slate-600">
                  {translations.order.orderError.additionalInfo}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <div
          style={{ visibility: isWaitingForResponse ? "hidden" : "visible" }}
        >
          <PageHeader
            title={translations.order.pageHeader.title}
            subtitle={translations.order.pageHeader.subtitle}
            background="default"
          />
          <div className="relative bg-white w-full flex-wrap overflow-hidden flex flex-col items-center justify-center text-left text-inherit text-black font-inherit ">
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                // Assert e.target as an HTMLElement to access tagName
                const target = e.target as HTMLElement;
                if (e.key === "Enter" && target.tagName !== "TEXTAREA") {
                  e.preventDefault();
                }
              }}
            >
              <div className="flex flex-col items-center justify-center py-20 px-20 lg:px-12 md:px-8 sm:px-6">
                <div className="w-full flex flex-col flex-wrap items-center justify-center gap-10 max-w-[768px]">
                  <div className="flex flex-col flex-wrap items-center justify-center pt-0 px-0 pb-12 gap-4">
                    <div
                      ref={graphicRef}
                      className="self-stretch flex flex-col items-center justify-center gap-3"
                    >
                      <Heading
                        type="heading"
                        title={translations.order.graphicInfo.title}
                        description={translations.order.graphicInfo.description}
                        linkText={translations.order.graphicInfo.linkText}
                        linkHref="/graphic-preparation-site"
                      />
                    </div>

                    <div className="self-stretch flex flex-col items-start justify-center gap-4 text-sm text-slate-800 font-text-sm-font-semibold">
                      <div className="flex justify-center items-center w-full bg-white">
                        <div className="flex w-full h-full pt-4">
                          <FileUpload
                            uploadText={
                              translations.order.graphicInfo.uploadText
                            }
                            fileSizeText={
                              translations.order.graphicInfo.fileSizeText
                            }
                            buttonText={
                              translations.order.graphicInfo.buttonText
                            }
                            onFileChange={(file) => handleFileChange(file)}
                            isVerified={isFileVerified} // Add this line
                          />
                        </div>
                      </div>
                      <div className="self-stretch flex flex-row items-start justify-center gap-3 sm:flex-col">
                        <div className="flex-1 sm:w-full sm:flex-auto">
                          <Input
                            type="text"
                            label={
                              translations.order.graphicInfo.weTransferLabel
                            }
                            placeholder={
                              translations.order.graphicInfo
                                .weTransferPlaceholder
                            }
                            warningText={
                              translations.order.graphicInfo.weTransferWarning
                            }
                            validationRule={weTransferRegex}
                            onWheel={(e) => {
                              e.currentTarget.blur();
                            }}
                            className="w-full"
                            onValueChange={setWeTransferLink}
                            isVerified={isWeTransferLinkVerified}
                          />
                        </div>
                        <Button
                          variant="primary"
                          size="default_stretch"
                          className="h-10 mt-[29px] sm:mt-0"
                          onClick={(event) => {
                            event.preventDefault(); // This will prevent the form submission
                            window.open("https://wetransfer.com/", "_blank");
                          }}
                        >
                          {translations.order.graphicInfo.weTransferButton}
                        </Button>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-between gap-[8px]">
                        <Checkbox
                          labelText={
                            translations.order.graphicInfo.helpWithGraphics
                          }
                          helperText={
                            translations.order.graphicInfo
                              .helpWithGraphicsHelper
                          }
                          className="max-w-fit"
                          onCheckedChange={(checked) =>
                            setNeedHelpWithGraphics(
                              checked !== "indeterminate" ? checked : false
                            )
                          } // Add this line
                        />
                        <div className="w-20 flex flex-row items-center justify-center text-base text-black">
                          <div className="relative font-semibold">
                            {
                              translations.order.graphicInfo
                                .helpWithGraphicsPrice
                            }
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-center justify-center gap-3">
                        <div className="self-stretch flex flex-row items-start justify-center gap-3 sm:flex-col">
                          <div className="flex-1 sm:w-full sm:flex-auto">
                            <Input
                              type="number"
                              label={translations.order.graphicInfo.lengthLabel}
                              helpText={
                                translations.order.graphicInfo.lengthHelper
                              }
                              placeholder={
                                translations.order.graphicInfo.lengthPlaceholder
                              }
                              warningText={
                                translations.order.graphicInfo.lengthWarning
                              }
                              validationRule={dtfLengthRegex}
                              onWheel={(e) => {
                                e.currentTarget.blur();
                              }}
                              onValueChange={setLength}
                              isVerified={islengthVerified}
                            />
                          </div>
                        </div>
                        <div className="flex justify-center items-center w-full h-96 bg-white">
                          <Textarea
                            label={translations.order.graphicInfo.noteLabel}
                            placeholder={
                              translations.order.graphicInfo.notePlaceholder
                            }
                            onChange={(e) => setNote(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-center pt-0 px-0 pb-12 gap-[32px] text-sm font-text-sm-font-semibold">
                    <div
                      ref={invoiceRef}
                      className="self-stretch flex flex-col items-start justify-start gap-[12px]"
                    >
                      <Heading
                        type="heading"
                        title={translations.order.invoiceInfo.title}
                        description={translations.order.invoiceInfo.description}
                      />
                    </div>

                    <div className="self-stretch flex flex-col items-start justify-center gap-3 text-slate-500">
                      <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                        <Heading
                          type="subheading"
                          title={translations.order.invoiceInfo.invoiceAddress}
                        />
                        <Checkbox
                          className="max-w-fit"
                          labelText={
                            translations.order.invoiceInfo.companyPurchase
                          }
                          onCheckedChange={(checked) =>
                            setIsCompany(
                              checked !== "indeterminate" ? checked : false
                            )
                          } // Add this line
                        />
                        <Checkbox
                          className="max-w-fit mt-[-16px]"
                          labelText={translations.order.invoiceInfo.vatPayer}
                          onCheckedChange={(checked) =>
                            setIsVatPayer(
                              checked !== "indeterminate" ? checked : false
                            )
                          } // Add this line
                        />
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-center gap-[12px]">
                        <div className="self-stretch flex flex-row items-center justify-center gap-[12px] align-bottom sm:flex-col">
                          <div className="self-stretch flex-1">
                            <Input
                              type="text"
                              label={translations.order.invoiceInfo.nameLabel}
                              warningText={
                                translations.order.invoiceInfo.nameWarning
                              }
                              validationRule={nameRegex}
                              onValueChange={setNameAndSurname}
                              autoComplete="name"
                              isVerified={isNameVerified}
                            />
                          </div>
                          <div className="self-stretch flex-1">
                            <Input
                              type="text"
                              label={
                                translations.order.invoiceInfo.companyLabel
                              }
                              optional={!isCompany}
                              validationRule={companyRegex}
                              warningText={
                                translations.order.invoiceInfo.companyWarning
                              }
                              onValueChange={setCompany}
                              autoComplete="organization"
                              isVerified={isCompanyVerified}
                            />
                          </div>
                        </div>

                        <div className="self-stretch flex flex-row items-start justify-start gap-[12px] sm:flex-col">
                          <div className="self-stretch flex-1">
                            <Input
                              type="email"
                              label={translations.order.invoiceInfo.emailLabel}
                              warningText={
                                translations.order.invoiceInfo.emailWarning
                              }
                              validationRule={emailRegex}
                              onValueChange={setEmail}
                              autoComplete="email"
                              isVerified={isEmailVerified}
                            />
                          </div>
                          <div className="self-stretch flex-1">
                            <Input
                              type="tel"
                              label={translations.order.invoiceInfo.phoneLabel}
                              warningText={
                                translations.order.invoiceInfo.phoneWarning
                              }
                              validationRule={phoneNumberRegex}
                              onValueChange={setPhoneNumber}
                              autoComplete="tel"
                              isVerified={isNumberVerified}
                            />
                          </div>
                        </div>

                        {isCompany && (
                          <div className="self-stretch flex flex-row items-start justify-start gap-[12px] sm:flex-col">
                            <div className="self-stretch flex-1">
                              <Input
                                type="text"
                                label={translations.order.invoiceInfo.crnLabel}
                                warningText={
                                  translations.order.invoiceInfo.crnWarning
                                }
                                validationRule={crnRegex}
                                onValueChange={setCRN}
                                autoComplete="crn"
                                isVerified={isCrnVerified}
                              />
                            </div>
                            <div className="self-stretch flex-1">
                              <Input
                                type="text"
                                label={translations.order.invoiceInfo.taxLabel}
                                warningText={
                                  translations.order.invoiceInfo.taxWarning
                                }
                                validationRule={taxRegex}
                                onValueChange={setTaxNumber}
                                autoComplete="tax"
                                isVerified={isTaxVerified}
                              />
                            </div>
                            <div className="self-stretch flex-1">
                              <Input
                                type="text"
                                label={translations.order.invoiceInfo.vatLabel}
                                optional={!isVatPayer}
                                warningText={
                                  translations.order.invoiceInfo.vatWarning
                                }
                                onValueChange={setVat}
                                autoComplete="vat"
                                isVerified={isVatVerified}
                              />
                            </div>
                          </div>
                        )}

                        <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                          <div className="self-stretch flex-[75%]">
                            <Input
                              type="text"
                              label={translations.order.invoiceInfo.streetLabel}
                              warningText={
                                translations.order.invoiceInfo.streetWarning
                              }
                              validationRule={streetRegex}
                              onValueChange={setStreet}
                              autoComplete="address-line1"
                              isVerified={isStreetVerified}
                            />
                          </div>
                          <div className="self-stretch flex-[25%]">
                            <Input
                              type="text"
                              label={
                                translations.order.invoiceInfo.streetNumberLabel
                              }
                              warningText={
                                translations.order.invoiceInfo
                                  .streetNumberWarning
                              }
                              validationRule={streetNumberRegex}
                              onValueChange={setStreetNumber}
                              autoComplete="address-line2"
                              isVerified={isStreetNumberVerified}
                            />
                          </div>
                        </div>

                        <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                          <div className="self-stretch flex-[20%] sm:flex-[30%]">
                            <Input
                              type="text"
                              label={translations.order.invoiceInfo.zipLabel}
                              warningText={
                                translations.order.invoiceInfo.zipWarning
                              }
                              validationRule={zipRegex}
                              onValueChange={setZip}
                              isVerified={isZipVerified}
                            />
                          </div>
                          <div className="self-stretch flex-[80%] sm:flex-[70%]">
                            <Input
                              type="text"
                              label={translations.order.invoiceInfo.cityLabel}
                              warningText={
                                translations.order.invoiceInfo.cityWarning
                              }
                              validationRule={cityRegex}
                              onValueChange={setCity}
                              autoComplete="address-level2"
                              isVerified={isCityVerified}
                            />
                          </div>
                        </div>

                        <div className="self-stretch flex flex-row">
                          <Select
                            name="country"
                            autoComplete="country"
                            defaultValue="191"
                            onValueChange={(value) => {
                              setCountry(value);
                            }}
                          >
                            <SelectTrigger
                              label={
                                translations.order.invoiceInfo.countryLabel
                              }
                              optional={false}
                            >
                              <SelectValue
                                placeholder={
                                  translations.order.invoiceInfo
                                    .countryPlaceholder
                                }
                              />
                              <SelectContent>
                                <SelectGroup>
                                  <SelectItem value="191">
                                    {translations.order.invoiceInfo.slovakia}
                                  </SelectItem>
                                  <SelectItem value="57">
                                    {
                                      translations.order.invoiceInfo
                                        .czechRepublic
                                    }
                                  </SelectItem>
                                  <SelectItem value="14">
                                    {translations.order.invoiceInfo.austria}
                                  </SelectItem>
                                  <SelectItem value="172">
                                    {translations.order.invoiceInfo.poland}
                                  </SelectItem>
                                  <SelectItem value="99">
                                    {translations.order.invoiceInfo.hungary}
                                  </SelectItem>
                                  <SelectItem value="152">
                                    {translations.order.invoiceInfo.netherlands}
                                  </SelectItem>
                                  <SelectItem value="82">
                                    {translations.order.invoiceInfo.germany}
                                  </SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </SelectTrigger>
                          </Select>
                        </div>
                      </div>
                      <Checkbox
                        labelText={
                          translations.order.invoiceInfo.sameDeliveryAddress
                        }
                        className="max-w-fit"
                        defaultChecked={true}
                        onCheckedChange={(checked) =>
                          setSameDeliveryAddress(
                            checked !== "indeterminate" ? checked : false
                          )
                        } // Add this line
                      />

                      {!sameDeliveryAddress && (
                        <div
                          ref={deliveryRef}
                          className="self-stretch flex flex-col items-start justify-center gap-[12px]"
                        >
                          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                            <Heading
                              type="subheading"
                              title={
                                translations.order.invoiceInfo.deliveryAddress
                              }
                            />
                          </div>
                          <div className="self-stretch flex flex-row items-center justify-center gap-[12px] align-bottom sm:flex-col">
                            <div className="self-stretch flex-1">
                              <Input
                                type="text"
                                label={translations.order.invoiceInfo.nameLabel}
                                warningText={
                                  translations.order.invoiceInfo.nameWarning
                                }
                                validationRule={nameRegex}
                                onValueChange={setDeliveryNameAndSurname}
                                autoComplete="name"
                                isVerified={isDeliveryNameVerified}
                              />
                            </div>
                            <div className="self-stretch flex-1">
                              <Input
                                type="text"
                                label={
                                  translations.order.invoiceInfo.companyLabel
                                }
                                optional={true}
                                warningText={
                                  translations.order.invoiceInfo.companyWarning
                                }
                                onValueChange={setDeliveryCompany}
                                autoComplete="organization"
                              />
                            </div>
                          </div>

                          <div className="self-stretch flex flex-row items-start justify-start gap-[12px] sm:flex-col">
                            <div className="self-stretch flex-1">
                              <Input
                                type="email"
                                label={
                                  translations.order.invoiceInfo.emailLabel
                                }
                                warningText={
                                  translations.order.invoiceInfo.emailWarning
                                }
                                validationRule={emailRegex}
                                onValueChange={setDeliveryEmail}
                                autoComplete="email"
                                isVerified={isDeliveryEmailVerified}
                              />
                            </div>
                            <div className="self-stretch flex-1">
                              <Input
                                type="tel"
                                label={
                                  translations.order.invoiceInfo.phoneLabel
                                }
                                warningText={
                                  translations.order.invoiceInfo.phoneWarning
                                }
                                validationRule={phoneNumberRegex}
                                onValueChange={setDeliveryPhoneNumber}
                                autoComplete="tel"
                                isVerified={isDeliveryNumberVerified}
                              />
                            </div>
                          </div>

                          <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                            <div className="self-stretch flex-[75%]">
                              <Input
                                type="text"
                                label={
                                  translations.order.invoiceInfo.streetLabel
                                }
                                warningText={
                                  translations.order.invoiceInfo.streetWarning
                                }
                                validationRule={streetRegex}
                                onValueChange={setDeliveryStreet}
                                autoComplete="address-line1"
                                isVerified={isDeliveryStreetVerified}
                              />
                            </div>
                            <div className="self-stretch flex-[25%]">
                              <Input
                                type="text"
                                label={
                                  translations.order.invoiceInfo
                                    .streetNumberLabel
                                }
                                warningText={
                                  translations.order.invoiceInfo
                                    .streetNumberWarning
                                }
                                validationRule={streetNumberRegex}
                                onValueChange={setDeliveryStreetNumber}
                                autoComplete="address-line2"
                                isVerified={isDeliveryStreetNumberVerified}
                              />
                            </div>
                          </div>

                          <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                            <div className="self-stretch flex-[20%] sm:flex-[30%]">
                              <Input
                                type="text"
                                label={translations.order.invoiceInfo.zipLabel}
                                warningText={
                                  translations.order.invoiceInfo.zipWarning
                                }
                                validationRule={zipRegex}
                                onValueChange={setDeliveryZip}
                                isVerified={isDeliveryZipVerified}
                              />
                            </div>
                            <div className="self-stretch flex-[80%] sm:flex-[70%]">
                              <Input
                                type="text"
                                label={translations.order.invoiceInfo.cityLabel}
                                warningText={
                                  translations.order.invoiceInfo.cityWarning
                                }
                                validationRule={cityRegex}
                                onValueChange={setDeliveryCity}
                                autoComplete="address-level2"
                                isVerified={isDeliveryCityVerified}
                              />
                            </div>
                          </div>

                          <div className="self-stretch flex flex-row">
                            <Select
                              name="country"
                              defaultValue="SK"
                              onValueChange={(value) => {
                                setDeliveryCountry(value);
                              }}
                            >
                              <SelectTrigger
                                label={
                                  translations.order.invoiceInfo.countryLabel
                                }
                                optional={false}
                              >
                                <SelectValue
                                  placeholder={
                                    translations.order.invoiceInfo
                                      .countryPlaceholder
                                  }
                                />
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="191">
                                      {translations.order.invoiceInfo.slovakia}
                                    </SelectItem>
                                    <SelectItem value="57">
                                      {
                                        translations.order.invoiceInfo
                                          .czechRepublic
                                      }
                                    </SelectItem>
                                    <SelectItem value="14">
                                      {translations.order.invoiceInfo.austria}
                                    </SelectItem>
                                    <SelectItem value="172">
                                      {translations.order.invoiceInfo.poland}
                                    </SelectItem>
                                    <SelectItem value="99">
                                      {translations.order.invoiceInfo.hungary}
                                    </SelectItem>
                                    <SelectItem value="152">
                                      {
                                        translations.order.invoiceInfo
                                          .netherlands
                                      }
                                    </SelectItem>
                                    <SelectItem value="82">
                                      {translations.order.invoiceInfo.germany}
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </SelectTrigger>
                            </Select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="self-stretch flex flex-col items-center justify-center pt-0 px-0 gap-[32px]">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                      <Heading
                        type="heading"
                        title={translations.order.paymentDelivery.title}
                        description={
                          translations.order.paymentDelivery.description
                        }
                      />
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                      <Heading
                        type="subheading"
                        title={translations.order.paymentDelivery.paymentMethod}
                      />
                      <div className="self-stretch flex flex-row items-start justify-start gap-10 sm:gap-4 xs:gap-2">
                        <div className="self-stretch flex flex-col items-start justify-start">
                          <RadioGroup
                            id="payment"
                            defaultValue="proforma"
                            onChange={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              const target = e.target as HTMLInputElement;
                              if (paymentOption !== target.value) {
                                setPaymentOption(target.value);
                              }
                            }}
                          >
                            <RadioGroupItem
                              variant="default"
                              value="proforma"
                              label={
                                translations.order.paymentDelivery.proforma
                              }
                            />
                            <RadioGroupItem
                              variant="default"
                              value="cardpay"
                              disabled={false}
                              label={translations.order.paymentDelivery.credit}
                            />
                            <RadioGroupItem
                              variant="default"
                              disabled={true}
                              value="tatrapayplus"
                              label={
                                translations.order.paymentDelivery.tatrapayplus
                              }
                            />
                          </RadioGroup>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-start w-fit">
                          <div className="self-stretch flex flex-row items-start justify-start gap-2 xs:gap-1 mt-5 xs:mt-[26px]">
                            <img
                              className="relative h-5 xs:h-4"
                              alt="visa"
                              src={visa}
                            />
                            <img
                              className="relative h-5 xs:h-4"
                              alt="mastercard"
                              src={mastercard}
                            />
                            <img
                              className="relative h-5 xs:h-4"
                              alt="maestro"
                              src={maestro}
                            />
                            <img
                              className="relative h-5 xs:h-4"
                              alt="apple-pay"
                              src={apple_pay}
                            />
                            <img
                              className="relative h-5 xs:h-4"
                              alt="google-pay"
                              src={google_pay}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                      <Heading
                        type="subheading"
                        title={
                          translations.order.paymentDelivery.deliveryMethod
                        }
                      />
                      <div
                        ref={dateRef}
                        className="self-stretch flex-col w-full flex-grow items-start justify-start"
                      >
                        <RadioGroup
                          id="delivery"
                          defaultValue="dpd"
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            if (target.value !== lastValue) {
                              setLastValue(target.value);
                              setDeliveryOption(target.value);
                            }
                          }}
                        >
                          <RadioGroupItem
                            variant="default"
                            value="dpd"
                            label={translations.order.paymentDelivery.dpd}
                            helperText={
                              sameDeliveryAddress
                                ? country === "191"
                                  ? translations.order.paymentDelivery
                                      .dpdHelperZone0
                                  : ["57", "14", "172", "99"].includes(country)
                                  ? translations.order.paymentDelivery
                                      .dpdHelperZone1
                                  : translations.order.paymentDelivery
                                      .dpdHelperZone2
                                : deliveryCountry === "191"
                                ? translations.order.paymentDelivery
                                    .dpdHelperZone0
                                : ["57", "14", "172", "99"].includes(
                                    deliveryCountry
                                  )
                                ? translations.order.paymentDelivery
                                    .dpdHelperZone1
                                : translations.order.paymentDelivery
                                    .dpdHelperZone2
                            }
                            price={`+${`${deliveryCost}.00`}€`}
                            onChange={(e) => {
                              // your code here
                            }}
                          />
                          <RadioGroupItem
                            variant="default"
                            value="pickup"
                            label={translations.order.paymentDelivery.pickup}
                            helperText={
                              translations.order.paymentDelivery.pickupHelper
                            }
                            price="+0.00€"
                            onChange={(e) => {
                              e.stopPropagation();
                              // rest of your code
                            }}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                      <Heading
                        type="subheading"
                        title={translations.order.paymentDelivery.dispatchDate}
                      />
                      <div className="self-stretch flex-col w-full flex-grow items-start justify-start">
                        <RadioGroup
                          id="date"
                          defaultValue="asap"
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            if (target.value !== lastDateValue) {
                              setLastDateValue(target.value);
                              setDateOption(target.value);
                            }
                          }}
                        >
                          <RadioGroupItem
                            variant="default"
                            value="asap"
                            label={
                              translations.order.paymentDelivery
                                .dispatchDateAsap
                            }
                            helperText={
                              translations.order.paymentDelivery
                                .dispatchDateAsapHelper
                            }
                            onChange={(e) => {
                              // your code here
                            }}
                          />
                          <RadioGroupItem
                            variant="date_picker"
                            value="date"
                            label={
                              translations.order.paymentDelivery
                                .dispatchDatePicker
                            }
                            helperText={
                              translations.order.paymentDelivery
                                .dispatchDatePickerLabel
                            }
                            isButtonDisabled={dateOption === "asap"}
                            onDateChange={setSelectedDate}
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    {parseFloat(totalPrice.replace(/,/g, "")) > 4 &&
                      isDivVisible && (
                        <div className="z-50 fixed bottom-0 w-full flex flex-col items-center bg-white justify-center text-left text-5xl text-black font-text-lg-font-normal shadow-[0px_-4px_34px_rgba(0,_0,_0,_0.06)]">
                          <div className="self-stretch bg-white flex flex-row items-center justify-center py-7 px-20 lg:px-12 md:px-8 sm:px-6">
                            <div className="flex-1 flex flex-col items-center justify-center max-w-[768px]">
                              <div className="self-stretch flex flex-row items-center justify-between">
                                <div className="flex flex-row items-end justify-start gap-[4px]">
                                  <div className="flex flex-row items-center justify-start">
                                    <div className="flex flex-row items-center justify-start">
                                      <div className="relative leading-[150%] font-semibold text-2xl">
                                        {
                                          translations.order.paymentDelivery
                                            .totalPrice
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative text-base leading-[200%] text-slate-500">
                                    {
                                      translations.order.paymentDelivery
                                        .withoutVAT
                                    }
                                  </div>
                                </div>
                                <div className="relative leading-[150%] font-semibold text-2xl">
                                  {`${totalPrice}`}€
                                </div>
                              </div>
                              <div className="self-stretch flex flex-row items-center justify-between text-lg text-slate-600">
                                <div className="relative leading-[150%] sm:text-sm">
                                  {`DTF transfer ${Number(
                                    length
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}${
                                    translations.order.paymentDelivery.bm
                                  }, ${
                                    translations.order.paymentDelivery.delivery
                                  } ${deliveryPrice}€`}
                                </div>
                                <div className="relative leading-[150%]">
                                  {`${pricePerMeter}`}€/
                                  {translations.order.paymentDelivery.bm}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    <div
                      ref={consentRef}
                      className="self-stretch relative rounded-12xs bg-primary-gradient-to-r shadow-[0px_0px_0px_4px_rgba(255,_255,_255,_0.01)] h-px"
                    />

                    <div className="self-stretch flex flex-col items-start justify-center text-sm text-red-400 font-text-sm-font-semibold">
                      <div className="self-stretch flex flex-col items-start justify-center gap-[16px] text-5xl text-black">
                        <div className="self-stretch flex flex-row items-center justify-between">
                          <div className="flex-1 flex flex-col items-start justify-start max-w-[768px]">
                            <div className="self-stretch flex flex-row items-center justify-between">
                              <div className="flex flex-row items-end justify-start gap-[4px]">
                                <div className="flex flex-row items-center justify-start">
                                  <div className="flex flex-row items-center justify-start">
                                    <div className="relative leading-[150%] font-semibold text-2xl">
                                      {
                                        translations.order.paymentDelivery
                                          .totalPrice
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="relative text-base leading-[200%] text-slate-500">
                                  {
                                    translations.order.paymentDelivery
                                      .withoutVAT
                                  }
                                </div>
                              </div>
                              <div className="relative leading-[150%] font-semibold text-2xl">
                                {`${totalPrice}`}€
                              </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-between text-lg text-slate-600">
                              <div className="relative leading-[150%] sm:text-sm">
                                {`DTF transfer ${Number(length).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}${translations.order.paymentDelivery.bm}, ${
                                  translations.order.paymentDelivery.delivery
                                } ${deliveryPrice}€`}
                              </div>
                              <div className="relative leading-[150%]">
                                {`${pricePerMeter}`}€/
                                {translations.order.paymentDelivery.bm}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="bottom_separator"
                        className="self-stretch flex flex-col items-start justify-center gap-4 text-xs text-slate-500 pt-10"
                      >
                        <Checkbox
                          className="max-w-fit"
                          labelText={
                            <span>
                              {translations.order.consent.text1}{" "}
                              <a
                                href="/terms-of-service"
                                className="font-semibold underline"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {translations.order.consent.text2}
                              </a>{" "}
                              {translations.order.consent.text3}{" "}
                              <a
                                href="/privacy-policy"
                                className="font-semibold underline"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {translations.order.consent.text4}
                              </a>
                              .
                            </span>
                          }
                          onCheckedChange={(checked) =>
                            setConsent(
                              checked !== "indeterminate" ? checked : false
                            )
                          } // Add this line
                        />
                        <Button
                          variant="default"
                          size="default_stretch"
                          type="submit"
                          className="uppercase"
                        >
                          {translations.order.submitButton}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      }
    </div>
  );
};

export default Order;
