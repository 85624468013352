"use client";

import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";
import { format } from "date-fns";
import { sk, de, cs, enUS } from "date-fns/locale";
import { Calendar as CalendarIcon } from "lucide-react";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "src/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import { addDays, isBefore, isAfter } from "date-fns";
import { useTranslation } from "../../TranslationContext";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const radioGroupItemVariants = cva(
  "aspect-square h-4 w-4 rounded-full border border-fuchsia-600 text-sm ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "border-fuchsia-600 text-sm",
        date_picker: "border-fuchsia-600 text-secondary",
        // Add more variants here
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

// Add disabled to the RadioGroupItemProps interface
export interface RadioGroupItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    VariantProps<typeof radioGroupItemVariants> {
  label?: string;
  helperText?: string;
  disabled?: boolean;
  price?: string;
  onDateChange?: (date: Date) => void;
  isButtonDisabled?: boolean; // Add this line
}

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(
  (
    {
      className,
      children,
      variant,
      label,
      helperText,
      disabled,
      price,
      onDateChange,
      isButtonDisabled, // Destructure isButtonDisabled here
      ...props
    },
    ref
  ) => {
    const [date, setDate] = React.useState<Date>();
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
      if (date && onDateChange) {
        onDateChange(date);
      }
    }, [date, onDateChange]);

    const handleDateChange = (selectedDate: Date | undefined) => {
      const tomorrow = addDays(new Date(), 1);
      const thirtyDaysFromNow = addDays(new Date(), 30);

      if (
        selectedDate &&
        !isBefore(selectedDate, tomorrow) &&
        !isAfter(selectedDate, thirtyDaysFromNow)
      ) {
        setDate(selectedDate);
        setIsOpen(false); // Close the popover after date selection
      }
    };

    const localeMap = {
      sk: sk,
      de: de,
      cs: cs,
      enUS: enUS,
    };

    const tomorrow = addDays(new Date(), 0);
    const thirtyDaysFromNow = addDays(new Date(), 30);
    const translations = useTranslation();
    const locale =
      localeMap[translations.radioGroup.locale as keyof typeof localeMap] ||
      enUS;

    const filterDate = (date: Date) => {
      return !isBefore(date, tomorrow) && !isAfter(date, thirtyDaysFromNow);
    };

    const [selectedValue, setSelectedValue] = React.useState<string | null>(
      null
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      console.log("Radio button checked:", e.target.value);
    };

    return (
      <div>
        <RadioGroupPrimitive.Item
          ref={ref}
          value={props.value} // Add this line
          disabled={disabled}
          className={`self-stretch leading-[24px] flex flex-col w-full ${
            disabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <div className="flex items-center w-full sm:items-start">
            <div
              className={cn(
                "rounded-3xs bg-slate-50 min-w-[16px] min-h-[16px] flex flex-row items-center justify-center",
                radioGroupItemVariants({ variant, className })
              )}
            >
              <RadioGroupPrimitive.Indicator className="flex items-center justify-center relative rounded-full bg-fuchsia-600 w-2.5 h-2.5">
                <Circle className="w-2.5 h-2.5 fill-fuchsia-600 text-fuchsia-600" />
              </RadioGroupPrimitive.Indicator>
            </div>
            <div className="flex items-center justify-between w-full pl-2.5">
              {variant === "date_picker" ? (
                <div className="flex flex-row items-center justify-between gap-2 sm:flex-col sm:items-start w-full">
                  <div className="leading-[125%] font-medium text-sm text-slate-800">
                    {label}
                  </div>
                  <div className="flex w-[220px] h-[17.5px] md:h-[40px] mb-2">
                    <Popover open={isOpen} onOpenChange={setIsOpen}>
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          size={"date_picker"}
                          disabled={isButtonDisabled}
                          className={cn(
                            "flex-grow justify-start text-left font-normal bg-slate-50 text-slate-950 border-slate-200",
                            !date && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4 text-slate-900" />
                          {date ? (
                            format(date, "PPP", { locale })
                          ) : (
                            <span className="text-slate-900">
                              {translations.radioGroup.datePicker}
                            </span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0">
                        <Calendar
                          mode="single"
                          selected={date}
                          onSelect={handleDateChange}
                          initialFocus
                          locale={locale}
                          disabled={(date) => {
                            const startDate = new Date(2023, 11, 16); // Months are 0-indexed in JavaScript
                            const endDate = new Date(2024, 0, 6);
                            return (
                              date < new Date() ||
                              date > addDays(new Date(), 30) ||
                              date.getDay() === 0 ||
                              date.getDay() === 6 ||
                              (date >= startDate && date <= endDate)
                            );
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              ) : (
                <div className="leading-[125%] font-medium text-sm text-slate-800">
                  {label}
                </div>
              )}
              {price && (
                <span className="justify-center text-bold text-slate-800 max-h-[17.5px] text-base">
                  {price}
                </span>
              )}
            </div>
          </div>
          {helperText && (
            <div className="relative leading-[150%] text-sm ml-6 text-slate-500 md:max-w-[270px] text-left">
              {helperText}
            </div>
          )}
        </RadioGroupPrimitive.Item>
      </div>
    );
  }
);

RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem, radioGroupItemVariants };
