import { useState, useEffect } from "react"; // Import useEffect here
import { FunctionComponent } from "react";
import { useTranslation } from "../../TranslationContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/custom/select_language";

import { ReactComponent as SK } from "../../assets/svg/SK.svg";
import { ReactComponent as CZ } from "../../assets/svg/CZ.svg";
import { ReactComponent as DE } from "../../assets/svg/DE.svg";
import { ReactComponent as EU } from "../../assets/svg/EU.svg";

const TopBar: FunctionComponent = () => {
  const translations = useTranslation();

  const getLanguageFromHostname = () => {
    const hostname = window.location.hostname;
    const languageMap: { [key: string]: string } = {
      "dtf.nexprim.com": "EN",
      "dtfprint.cz": "CZ",
      "dtfprint.at": "DE",
      default: "SK",
    };
    return languageMap[hostname] || languageMap["default"];
  };

  const [selectedLanguage, setSelectedLanguage] = useState(
    getLanguageFromHostname()
  ); // Initialize with getLanguageFromHostname

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname === "localhost") return;
    if (hostname === "192.168.1.139") return;

    const pathname = window.location.pathname; // Get the current pathname
    let targetHostname;
    let url;
    switch (selectedLanguage) {
      case "EN":
        targetHostname = "dtf.nexprim.com";
        url =
          pathname === "/order"
            ? "https://dtf.nexprim.com/"
            : `https://dtf.nexprim.com${pathname}`;
        break;
      case "CZ":
        targetHostname = "dtfprint.cz";
        url =
          pathname === "/order"
            ? "https://dtfprint.cz/"
            : `https://dtfprint.cz${pathname}`;
        break;
      case "DE":
        targetHostname = "dtfprint.at";
        url =
          pathname === "/order"
            ? "https://dtfprint.at/"
            : `https://dtfprint.at${pathname}`;
        break;
      case "SK":
        targetHostname = "dtfprint.sk";
        url =
          pathname === "/order"
            ? "https://dtfprint.sk/"
            : `https://dtfprint.sk${pathname}`;
        break;
      default:
        return;
    }
    if (hostname !== targetHostname) {
      window.location.href = url;
    }
  }, [selectedLanguage]); // Dependency array, effect runs when selectedLanguage changes

  const getIcon = (language: string) => {
    switch (language) {
      case "EN":
        return <EU className="w-8 h-auto" />;
      case "CZ":
        return <CZ className="w-8 h-auto" />;
      case "DE":
        return <DE className="w-8 h-auto" />;
      case "SK":
        return <SK className="w-8 h-auto" />;
      default:
        return <SK className="w-8 h-auto" />;
    }
  };

  const getLanguageLabel = (language: string) => {
    switch (language) {
      case "EN":
        return "EN";
      case "CZ":
        return "CZ";
      case "DE":
        return "DE";
      case "SK":
        return "SK";
      default:
        return "SK";
    }
  };
  return (
    <div
      id="topbar"
      className="relative bg-slate-950 w-full flex flex-row py-2 px-14 box-border items-center justify-center text-center text-base text-white font-text-base-font-normal sm:px-8 xs:px-4 xs:text-sm"
    >
      <div className="[text-decoration:none] flex-1 flex flex-row flex-wrap items-center justify-center gap-[3px] text-[inherit] max-w-[1440px] ">
        <div className="[text-decoration:none] flex-1 flex flex-row flex-wrap items-center justify-center gap-[3px] text-[inherit]">
          <div className="relative leading-[150%]">
            <span className="inline sm:hidden">
              {translations.topbar.info.text1}
            </span>
            <a
              href={translations.topbar.info.link}
              className="text-gradient relative leading-[150%] font-bold uppercase underline decoration-fuchsia-500"
            >
              {translations.topbar.info.highlight1}
            </a>
            {translations.topbar.info.text2}
          </div>
        </div>

        <div className="self-stretch flex flex-row relative items-center z-60">
          <Select
            name="language"
            autoComplete="language"
            defaultValue={selectedLanguage}
            onValueChange={setSelectedLanguage}
          >
            <SelectTrigger className="flex items-center justify-between bg-gray-800 text-white rounded-lg px-4 py-2 shadow-lg border-0 w-full">
              <div className="flex items-center space-x-2">
                {getIcon(selectedLanguage)}
                <span className="font-medium pr-2 sm:hidden">
                  {getLanguageLabel(selectedLanguage)}
                </span>
              </div>
            </SelectTrigger>
            <SelectContent className="bg-gray-900 text-white rounded-lg shadow-lg border-0 w-full">
              <SelectGroup>
                <SelectItem
                  value="SK"
                  className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                >
                  <SK className="w-8 h-auto mr-2 " />{" "}
                  <span className="inline sm:hidden">Slovenčina</span>
                </SelectItem>
                <SelectItem
                  value="CZ"
                  className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                >
                  <CZ className="w-8 h-auto mr-2" />{" "}
                  <span className="inline sm:hidden">Čeština</span>
                </SelectItem>
                <SelectItem
                  value="EN"
                  className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                >
                  <EU className="w-8 h-auto mr-2" />{" "}
                  <span className="inline sm:hidden">English</span>
                </SelectItem>
                <SelectItem
                  value="DE"
                  className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                >
                  <DE className="w-8 h-auto mr-2" />{" "}
                  <span className="inline sm:hidden">Deutsch</span>
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
