import React from "react";
import PageHeader from "../ui/custom/page_header";
import TextContainer from "../ui/custom/text_container";

import tablerpointfilled from "../../assets/svg/tablerpointfilled.svg";
import bad_line from "../../assets/png/bad_line.png";
import good_line from "../../assets/png/good_line.png";
import antialiasing_1 from "../../assets/png/antialiasing_1.jpg";
import antialiasing_2 from "../../assets/png/antialiasing_2.jpg";
import antialiasing_3 from "../../assets/png/antialiasing_3.jpg";
import antialiasing_4 from "../../assets/png/antialiasing_4.jpg";
import segment_1 from "../../assets/png/segment_1.jpg";
import segment_2 from "../../assets/png/segment_2.jpg";
import { useTranslation } from "../../TranslationContext";

const GraphicPreparationSite = () => {
  const translations = useTranslation();
  return (
    <div>
      <PageHeader title={translations.graphicPreparationPage.title} />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-center px-12 py-20 md:px-10 sm:px-6 sm:py-10 box-border text-left text-2xl sm:text-xl text-slate-950">
        <div className="w-full flex flex-col items-center justify-center gap-12 max-w-[1280px]">
          <div className="self-stretch relative text-xl sm:text-lg leading-[150%] font-semibold text-slate-950">
            <span>{translations.graphicPreparationPage.titleHighlight1}</span>
            <span className="text-gradient">
              {translations.graphicPreparationPage.titleHighlight2}
            </span>
            <span> {translations.graphicPreparationPage.titleHighlight3}.</span>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
            <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
              <b className="w-6 relative tracking-[-0.01em] leading-[150%] flex text-gradient items-center shrink-0">
                1.
              </b>
              <div className="flex flex-col items-start justify-start text-slate-950">
                <b className="relative tracking-[-0.01em] leading-[150%]">
                  {translations.graphicPreparationPage.graphicDimensions.title}
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start py-0 pr-0 pl-[30px] md:pl-0 gap-[8px] text-sm sm:text-xs">
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.graphicDimensions
                          .width.text
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.graphicDimensions
                          .width.highlight
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.graphicDimensions
                          .length.text
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.graphicDimensions
                          .length.highlight
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.graphicDimensions
                          .canvas.text
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.graphicDimensions
                          .canvas.highlight
                      }
                    </span>
                    <span>
                      {" "}
                      {
                        translations.graphicPreparationPage.graphicDimensions
                          .canvas.text2
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
            <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
              <b className="w-6 relative tracking-[-0.01em] leading-[150%] flex text-gradient items-center shrink-0">
                2.
              </b>
              <div className="flex flex-col items-start justify-start text-slate-950">
                <b className="relative tracking-[-0.01em] leading-[150%]">
                  {translations.graphicPreparationPage.graphicColors.title}
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start py-0 pr-0 pl-[30px] md:pl-0 gap-[8px] text-sm sm:text-xs">
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {translations.graphicPreparationPage.colorSpace.text1}
                    </span>
                    <span className="font-semibold">
                      {translations.graphicPreparationPage.colorSpace.highlight}
                    </span>
                    <span>
                      {translations.graphicPreparationPage.colorSpace.text2}
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {translations.graphicPreparationPage.whiteColor.text}
                    </span>
                    <span className="font-semibold">
                      {translations.graphicPreparationPage.whiteColor.highlight}
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {translations.graphicPreparationPage.blackColor.text}
                    </span>
                    <span className="font-semibold">
                      {translations.graphicPreparationPage.blackColor.highlight}
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    {
                      translations.graphicPreparationPage
                        .monitorCalibrationWarning
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
            <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
              <b className="w-6 relative tracking-[-0.01em] leading-[150%] flex text-gradient items-center shrink-0">
                3.
              </b>
              <div className="flex flex-col items-start justify-start text-slate-950">
                <b className="relative tracking-[-0.01em] leading-[150%]">
                  {translations.graphicPreparationPage.graphicDetails.title}
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start py-0 pr-0 pl-[30px] md:pl-0 gap-[8px] text-sm sm:text-xs">
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.graphicDetails
                          .thinestLine.text
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.graphicDetails
                          .thinestLine.highlight
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.graphicDetails
                          .zeroThickness.text
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.graphicDetails
                          .zeroThickness.highlight
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-center justify-start py-0 px-6 gap-[16px] text-base sm:text-sm text-slate-600">
                <div className="self-stretch relative leading-[150%]">
                  {
                    translations.graphicPreparationPage.zeroThicknessWarning
                      .text
                  }
                </div>
                <div className="self-stretch overflow-hidden flex flex-row md:flex-col items-start justify-start gap-12 md:gap-0 text-lg sm:text-base text-slate-900">
                  <div className="flex flex-col items-center justify-start py-4 px-0 gap-[16px]">
                    <div className="self-stretch h-[72px] flex flex-col items-start justify-center gap-[20px]">
                      <img
                        className="w-[230.48px] relative h-[72px]"
                        alt=""
                        src={bad_line}
                      />
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start">
                      <div className="self-stretch relative leading-[150%]">
                        {translations.graphicPreparationPage.badLine.title}
                      </div>
                      <div className="self-stretch relative text-base sm:text-sm leading-[150%] text-slate-600">
                        {
                          translations.graphicPreparationPage.badLine
                            .description
                        }
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-start py-4 px-0 gap-[16px]">
                    <div className="self-stretch h-[72px] flex flex-col items-start justify-center gap-[12px]">
                      <img
                        className="w-[230.48px] relative h-[72px]"
                        alt=""
                        src={good_line}
                      />
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start">
                      <div className="self-stretch relative leading-[150%]">
                        {translations.graphicPreparationPage.correctLine.title}
                      </div>
                      <div className="relative text-base sm:text-sm leading-[150%] text-slate-600">
                        {
                          translations.graphicPreparationPage.correctLine
                            .description
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {translations.graphicPreparationPage.bitmapElements.text1}
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.bitmapElements
                          .highlight
                      }
                    </span>
                    <span>
                      {" "}
                      {translations.graphicPreparationPage.bitmapElements.text2}
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    {
                      translations.graphicPreparationPage.transparentPixels
                        .text1
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start py-0 gap-[16px] text-base sm:text-sm text-slate-600">
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
                <div className="self-stretch relative leading-[150%] px-[54px] md:px-6">
                  {translations.graphicPreparationPage.whiteEdge.text1}
                </div>
                <div className="self-stretch relative leading-[150%] px-[54px] md:px-6">
                  {translations.graphicPreparationPage.whiteEdge.text2}
                </div>
              </div>
              <div className="self-stretch overflow-hidden justify-items-center text-center text-lg sm:text-base text-slate-900 max-w-[1080px] lg:max-w-[540px] grid grid-cols-4 lg:grid-cols-2 sm:grid-cols-1">
                <div className="flex flex-col items-center justify-center">
                  <div className="self-stretch flex flex-col items-center justify-center gap-[16px] w-[220px]">
                    <img
                      className="w-[200px] relative h-[125px] object-cover"
                      alt=""
                      src={antialiasing_1}
                    />
                    <div className="self-stretch relative leading-[150%] font-semibold">
                      {translations.graphicPreparationPage.antialiasing.example}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="self-stretch flex flex-col items-center justify-center gap-[16px] w-[220px]">
                    <img
                      className="w-[200px] relative h-[125px] object-cover"
                      alt=""
                      src={antialiasing_2}
                    />
                    <div className="self-stretch relative leading-[150%] font-semibold">
                      {
                        translations.graphicPreparationPage
                          .spotLayerRepresentation
                      }
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="self-stretch flex flex-col items-center justify-center gap-[16px] w-[220px]">
                    <img
                      className="w-[200px] relative h-[125px] object-cover"
                      alt=""
                      src={antialiasing_3}
                    />
                    <div className="self-stretch relative leading-[150%] font-semibold">
                      {translations.graphicPreparationPage.darkBackgroundEffect}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="self-stretch flex flex-col items-center justify-center gap-[16px] w-[220px]">
                    <img
                      className="w-[200px] relative h-[125px] object-cover"
                      alt=""
                      src={antialiasing_4}
                    />
                    <div className="self-stretch relative leading-[150%] font-semibold">
                      {translations.graphicPreparationPage.fixedAntialiasing}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
            <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
              <b className="w-6 relative tracking-[-0.01em] leading-[150%] flex text-gradient items-center shrink-0">
                4.
              </b>
              <div className="flex flex-col items-start justify-start text-slate-950">
                <b className="relative tracking-[-0.01em] leading-[150%]">
                  {translations.graphicPreparationPage.exportGraphics.title}
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start py-0 pr-0 pl-[30px] md:pl-0 gap-[8px] text-sm sm:text-xs">
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {translations.graphicPreparationPage.largeFiles.text1}
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.largeFiles
                          .highlight1
                      }
                    </span>
                    <span>
                      {" "}
                      {translations.graphicPreparationPage.largeFiles.text2}
                    </span>
                    <span className="font-semibold">
                      {" "}
                      {
                        translations.graphicPreparationPage.largeFiles
                          .highlight2
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {translations.graphicPreparationPage.fileWidth.text}
                    </span>
                    <span className="font-semibold">
                      {translations.graphicPreparationPage.fileWidth.highlight}
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage
                          .exportWithoutBackground.text
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage
                          .exportWithoutBackground.highlight
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.exportResolution
                          .text1
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.exportResolution
                          .highlight1
                      }
                    </span>
                    <span>
                      {
                        translations.graphicPreparationPage.exportResolution
                          .text2
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.exportResolution
                          .highlight2
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.disableAntialiasing
                          .text
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.disableAntialiasing
                          .highlight
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {translations.graphicPreparationPage.vectorFormat.text1}
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.vectorFormat
                          .highlight
                      }
                    </span>
                    <span>
                      {translations.graphicPreparationPage.vectorFormat.text2}
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.tiffCompression
                          .text1
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.tiffCompression
                          .highlight
                      }
                    </span>
                    <span>
                      {
                        translations.graphicPreparationPage.tiffCompression
                          .text2
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {
                        translations.graphicPreparationPage.repeatingSegments
                          .text1
                      }
                    </span>
                    <span className="font-semibold">
                      {
                        translations.graphicPreparationPage.repeatingSegments
                          .highlight
                      }
                    </span>
                    <span>
                      {
                        translations.graphicPreparationPage.repeatingSegments
                          .text2
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative leading-[150%]">
                    <span>
                      {translations.graphicPreparationPage.lastStep.text1}
                    </span>
                    <span className="font-semibold">
                      {translations.graphicPreparationPage.lastStep.highlight}
                    </span>
                    <span>
                      {translations.graphicPreparationPage.lastStep.text2}
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-center justify-start py-0 px-6 text-center text-lg md:text-base sm:text-sm text-slate-900">
                <div className="self-stretch overflow-hidden flex flex-row sm:flex-col-reverse items-start justify-start gap-16 sm:gap-4">
                  <div className="flex flex-col items-center justify-start gap-[16px]">
                    <img
                      className="w-[203px] relative sm:max-w-[140px] object-contain min-h-[10px]"
                      alt=""
                      src={segment_1}
                    />
                    <div className="self-stretch flex flex-col items-center justify-start">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        <p className="m-0">
                          {
                            translations.graphicPreparationPage.segments
                              .oneSegment.title
                          }
                        </p>
                        <p className="m-0">
                          {
                            translations.graphicPreparationPage.segments
                              .oneSegment.size
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-start gap-[16px]">
                    <img
                      className="w-[620.13px] relative object-contain"
                      alt=""
                      src={segment_2}
                    />
                    <div className="self-stretch flex flex-col items-center justify-start">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        <p className="m-0">
                          {
                            translations.graphicPreparationPage.segments
                              .threeSegments.title
                          }
                        </p>
                        <p className="m-0">
                          {
                            translations.graphicPreparationPage.segments
                              .threeSegments.size
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
            <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
              <b className="w-6 relative tracking-[-0.01em] leading-[150%] flex text-gradient items-center shrink-0">
                5.
              </b>
              <div className="flex flex-col items-start justify-start text-slate-950">
                <b className="relative tracking-[-0.01em] leading-[150%]">
                  {translations.graphicPreparationPage.sampleGraphic.title}
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start py-0 pr-0 pl-[30px] md:pl-0 gap-[8px] text-sm sm:text-xs">
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative [text-decoration:underline] leading-[150%]">
                    <a
                      href="http://www.nexprim.com/downloads/dtf-sample-vector.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      {translations.graphicPreparationPage.sampleGraphic.text1}
                    </a>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative [text-decoration:underline] leading-[150%]">
                    <a
                      href="http://www.nexprim.com/downloads/dtf-sample-bitmap.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      {translations.graphicPreparationPage.sampleGraphic.text2}
                    </a>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={tablerpointfilled}
                />
                <div className="flex flex-col items-start justify-start text-lg sm:text-base text-slate-900">
                  <div className="self-stretch relative [text-decoration:underline] leading-[150%]">
                    <a
                      href="http://www.nexprim.com/downloads/dtf-sample-mixed.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      {translations.graphicPreparationPage.sampleGraphic.text3}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start text-sm sm:text-xs ">
            <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
              <div className="flex flex-col items-start justify-start text-xl sm:text-lg text-slate-800">
                <div className="relative leading-[150%]">
                  {translations.graphicPreparationPage.finalNote}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphicPreparationSite;
