import React from "react";
import PageHeader from "../ui/custom/page_header";
import TextContainer from "../ui/custom/text_container";
import { useTranslation } from "../../TranslationContext";

const PaymentPolicy = () => {
  const translations = useTranslation();
  const { paymentPolicy } = translations;

  return (
    <div>
      <PageHeader title={paymentPolicy.pageTitle} />
      <TextContainer>
        <p className="m-0 pb-2 uppercase font-semibold">{paymentPolicy.sections[0].title}</p>
        <p className="m-0">{paymentPolicy.sections[0].content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>

        <p className="m-0 pb-2 uppercase font-semibold">{paymentPolicy.sections[1].title}</p>
        <p className="m-0">{paymentPolicy.sections[1].content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>

        <p className="m-0 pb-2 uppercase font-semibold">{paymentPolicy.sections[2].title}</p>
        <p className="m-0">{paymentPolicy.sections[2].content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>

        <p className="m-0 pb-2 uppercase font-semibold">{paymentPolicy.sections[3].title}</p>
        <p className="m-0">{paymentPolicy.sections[3].content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>

        <p className="m-0 pb-2 uppercase font-semibold">{paymentPolicy.sections[4].title}</p>
        <p className="m-0">{paymentPolicy.sections[4].content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>

        <p className="m-0 pb-2 uppercase font-semibold">{paymentPolicy.sections[5].title}</p>
        <p className="m-0">{paymentPolicy.sections[5].content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>

        <p className="m-0 pb-2 uppercase font-semibold">{paymentPolicy.sections[6].title}</p>
        <p className="m-0">{paymentPolicy.sections[6].content}</p>
        <p className="m-0">&nbsp;</p>
        <p className="m-0">&nbsp;</p>

        <p className="m-0">{paymentPolicy.contactInfo}</p>
      </TextContainer>
    </div>
  );
};

export default PaymentPolicy;