import React, { FunctionComponent } from "react";
import { useTranslation } from "../../TranslationContext";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";

import OrderIcon from "../../assets/custom_icons/OrderIcon";
import QualityIcon from "../../assets/custom_icons/QualityIcon";
import DeliveryIcon from "../../assets/custom_icons/DeliveryIcon";
import PaymentIcon from "../../assets/custom_icons/PaymentIcon";
import RefundIcon from "../../assets/custom_icons/RefundIcon";
import GraphicsIcon from "../../assets/custom_icons/GraphicsIcon";
import ShirtIcon from "../../assets/custom_icons/ShirtIcon";

interface FAQProps {
  isTitleVisible?: boolean;
}

const FAQ: FunctionComponent<FAQProps> = ({ isTitleVisible = true }) => {
  const translations = useTranslation();
  return (
    <div
      id="faq"
      className="relative bg-white w-full overflow-hidden flex flex-col py-24 px-20 box-border items-center justify-start text-center text-4xl text-livid-900 sm:px-12 xs:px-6 xs:py-16 "
    >
      <div className="max-w-[700px] w-full flex flex-col items-center justify-start gap-12 no-underline hover:no-underline">
        {isTitleVisible && (
          <div className="self-stretch flex flex-col items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[125%] font-extrabold flex items-center justify-center w-full ">
            {translations.faq.title}
            </div>
          </div>
        )}
        <div className="w-full">
          <Accordion type="single" collapsible>
            <AccordionItem className="border-0" value="item-1-1">
              <AccordionTrigger variant="bold" icon={OrderIcon}>
              {translations.faq.orderAndProduction.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-1-6">
                    <AccordionTrigger>
                    {translations.faq.orderAndProduction.transferWidth.question}
                    </AccordionTrigger>
                    <AccordionContent>
                    {translations.faq.orderAndProduction.transferWidth.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-2">
                    <AccordionTrigger>
                    {translations.faq.orderAndProduction.productionTime.question}
                    </AccordionTrigger>
                    <AccordionContent>
                    {translations.faq.orderAndProduction.productionTime.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-3">
                    <AccordionTrigger>
                      {translations.faq.orderAndProduction.minimumLength.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.orderAndProduction.minimumLength.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-4">
                    <AccordionTrigger>
                      {translations.faq.orderAndProduction.orderModification.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.orderAndProduction.orderModification.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-1-5">
                    <AccordionTrigger>
                      {translations.faq.orderAndProduction.sampleOffer.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.orderAndProduction.sampleOffer.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-0" value="item-2-1">
              <AccordionTrigger variant="bold" icon={QualityIcon}>
                {translations.faq.quality.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-2-2">
                    <AccordionTrigger>
                      {translations.faq.quality.productionProcess.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.quality.productionProcess.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-3">
                    <AccordionTrigger>
                      {translations.faq.quality.durability.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.quality.durability.answer}{" "}
                      <a
                        href="http://www.nexprim.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        www.nexprim.com
                      </a>
                      , respektíve{" "}
                      <a
                        href="http://www.qar.sk"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        www.qar.sk
                      </a>
                      .
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-4">
                    <AccordionTrigger>
                      {translations.faq.quality.washingInstructions.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.quality.washingInstructions.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-5">
                    <AccordionTrigger>
                      {translations.faq.quality.colorAccuracy.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.quality.colorAccuracy.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-7">
                    <AccordionTrigger>
                      {translations.faq.quality.pantoneColors.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.quality.pantoneColors.answer}{" "}
                      <a
                        href="http://www.nexprim.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        www.nexprim.com
                      </a>
                      , respektíve{" "}
                      <a
                        href="http://www.qar.sk"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        www.qar.sk
                      </a>
                      .
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-2-6">
                    <AccordionTrigger>
                      {translations.faq.quality.otherTransferTypes.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.quality.otherTransferTypes.answer}{" "}
                      <a
                        href="http://www.nexprim.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        www.nexprim.com
                      </a>
                      , respektíve{" "}
                      <a
                        href="http://www.qar.sk"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        www.qar.sk
                      </a>
                      .
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-0" value="item-7-1">
              <AccordionTrigger variant="bold" icon={ShirtIcon}>
                {translations.faq.pressingProcess.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-7-2">
                    <AccordionTrigger>
                      {translations.faq.pressingProcess.temperatureAndTime.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.pressingProcess.temperatureAndTime.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-7-5">
                    <AccordionTrigger>
                      {translations.faq.pressingProcess.pressure.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.pressingProcess.pressure.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-7-3">
                    <AccordionTrigger>
                      {translations.faq.pressingProcess.removingTransferFilm.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.pressingProcess.removingTransferFilm.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-7-4">
                    <AccordionTrigger>
                      {translations.faq.pressingProcess.repressing.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.pressingProcess.repressing.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-0" value="item-3-1">
              <AccordionTrigger variant="bold" icon={GraphicsIcon}>
              {translations.faq.graphicsPreparation.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-3-2">
                    <AccordionTrigger>
                      {translations.faq.graphicsPreparation.graphicsEditing.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.graphicsPreparation.graphicsEditing.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3-3">
                    <AccordionTrigger>
                      {translations.faq.graphicsPreparation.uploadIssues.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.graphicsPreparation.uploadIssues.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3-4">
                    <AccordionTrigger>
                      {translations.faq.graphicsPreparation.bestFileFormat.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.graphicsPreparation.bestFileFormat.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3-5">
                    <AccordionTrigger>
                      {translations.faq.graphicsPreparation.designConsultation.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.graphicsPreparation.designConsultation.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className="border-0" value="item-4-1">
              <AccordionTrigger variant="bold" icon={DeliveryIcon}>
                {translations.faq.delivery.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-4-2">
                    <AccordionTrigger>
                      {translations.faq.delivery.deliveryCost.question}
                      </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.delivery.deliveryCost.answer}
                      <a href={translations.faq.delivery.deliveryCost.link} className="text-gradient relative underline decoration-fuchsia-500">
                        {translations.faq.delivery.deliveryCost.linkText}
                      </a>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-3">
                    <AccordionTrigger>
                      {translations.faq.delivery.deliveryTime.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.delivery.deliveryTime.answer}
                      <a href={translations.faq.delivery.deliveryTime.link} className="text-gradient relative underline decoration-fuchsia-500">
                        {translations.faq.delivery.deliveryTime.linkText}
                      </a>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-4">
                    <AccordionTrigger>
                      {translations.faq.delivery.expressDelivery.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.delivery.expressDelivery.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-5">
                    <AccordionTrigger>
                      {translations.faq.delivery.deliveryCountries.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.delivery.deliveryCountries.answer}
                      <a href={translations.faq.delivery.deliveryCountries.link} className="text-gradient relative underline decoration-fuchsia-500">
                        {translations.faq.delivery.deliveryCountries.linkText}
                      </a>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4-6">
                    <AccordionTrigger>
                      {translations.faq.delivery.personalPickup.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.delivery.personalPickup.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-5-1">
              <AccordionTrigger variant="bold" icon={PaymentIcon}>
                {translations.faq.paymentsAndPrices.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-5-2">
                    <AccordionTrigger>
                    {translations.faq.paymentsAndPrices.paymentMethods.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.paymentsAndPrices.paymentMethods.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-6">
                    <AccordionTrigger>
                      {translations.faq.paymentsAndPrices.invoicePayment.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.paymentsAndPrices.invoicePayment.answer}Z
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-3">
                    <AccordionTrigger>
                      {translations.faq.paymentsAndPrices.bulkOrderDiscounts.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.paymentsAndPrices.bulkOrderDiscounts.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-4">
                    <AccordionTrigger>
                      {translations.faq.paymentsAndPrices.regularCustomerDiscounts.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.paymentsAndPrices.regularCustomerDiscounts.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-5-5">
                    <AccordionTrigger>
                      {translations.faq.paymentsAndPrices.vatInclusion.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.paymentsAndPrices.vatInclusion.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem className="border-0" value="item-6-1">
              <AccordionTrigger variant="bold" icon={RefundIcon}>
                {translations.faq.returnsAndWarranty.title}
              </AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-6-2">
                    <AccordionTrigger>
                      {translations.faq.returnsAndWarranty.returnsPolicy.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.returnsAndWarranty.returnsPolicy.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-6-3">
                    <AccordionTrigger>
                      {translations.faq.returnsAndWarranty.warranty.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.returnsAndWarranty.warranty.answer}
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-6-4">
                    <AccordionTrigger>
                      {translations.faq.returnsAndWarranty.damagedDelivery.question}
                    </AccordionTrigger>
                    <AccordionContent>
                      {translations.faq.returnsAndWarranty.damagedDelivery.answer}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
