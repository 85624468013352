// Importing necessary libraries and components
import React, { useEffect, useState, useRef } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "../../global.css";
import TopBar from "./topbar_language_select";
import { useTranslation } from "../../TranslationContext";

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "../ui/navigation-menu";

import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";

import { Button } from "../ui/button";

import logo from "../../assets/svg/nexprim.svg";

import { Menu } from "lucide-react";

// Header component definition
const Header: React.FC = () => {
  const translations = useTranslation();
  // Using the useLocation hook to get the current location
  const location = useLocation();

  // State variable for window width
  const [width, setWidth] = useState(window.innerWidth);

  const [orderPage, setOrderPage] = useState(false);

  // Refs for topbar and navbar
  const topbarRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);

  // State variable for Sheet open/close state
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  // Effect hook to update body padding and add event listeners for resize and scroll
  useEffect(() => {
    if (location.pathname === "/order") {
      setOrderPage(true);
    } else {
      setOrderPage(false);
    }

    updateBodyPadding();
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/") {
      document.body.style.overflow = "auto";
      const elementId = location.hash.slice(1); // remove the '#' from the hash
      const element = document.getElementById(elementId);
      if (element) {
        const offset = window.innerWidth <= 1023 ? 0 : -88; // no offset for mobile devices
        window.scrollTo({
          top:
            element.getBoundingClientRect().top + window.pageYOffset + offset,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  // Function to update body padding based on navbar height
  const updateBodyPadding = () => {
    const navbarHeight = navbarRef.current?.offsetHeight;
    const appDiv = document.getElementById("App");

    if (
      navbarRef.current?.classList.contains("fixed") &&
      navbarHeight &&
      appDiv
    ) {
      // appDiv.style.paddingTop = `${navbarHeight}px`;
    } else if (appDiv) {
      appDiv.style.paddingTop = "0";
    }
  };

  // Function to handle window size change
  const handleWindowSizeChange = () => {
    isMobile = window.innerWidth <= 1023;
    handleScroll();
  };

  // Variable to check if the device is mobile
  let isMobile = width <= 1023;
  let previousScrollPos = window.scrollY;

  // Function to handle scroll
  const handleScroll = () => {
    isMobile = window.innerWidth <= 1023;
    setWidth(window.innerWidth);
    const currentScrollPos = window.scrollY;

    const threshold = document.body.scrollHeight - window.innerHeight;

    if (isMobile) {
      navbarRef.current?.classList.add("fixed", "top-0");
      if (
        currentScrollPos > previousScrollPos ||
        currentScrollPos > threshold - 200
      ) {
        navbarRef.current?.classList.remove("fixed", "top-0");
      }
    } else {
      navbarRef.current?.classList.add("fixed", "top-0");
    }

    previousScrollPos = currentScrollPos;
    updateBodyPadding();
  };

  // Render the Header component
  return (
    <>
      <div className="relative" id="placeholder"></div>
      {
        <div className="topbar z-51 relative w-full bg-white" ref={topbarRef}>
          <TopBar />
        </div>
      }
      {isMobile ? (
        <div
          id="header"
          className="header sticky navbar z-50 w-full bg-white"
          ref={navbarRef}
        >
          <div className="flex justify-between items-center py-5 px-10">
            <RouterLink to="/">
              <img
                src={logo}
                alt="Logo"
                onClick={() => scroll.scrollToTop()}
                style={{ float: "left" }}
              />
            </RouterLink>
            <div>
              <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
                <SheetTrigger>
                  <Button variant="ghost" size="icon">
                    <Menu className="h-8 w-8" />
                  </Button>
                </SheetTrigger>
                <SheetContent side="right">
                  <div className="pt-10">
                    {location.pathname === "/" ? (
                      <ScrollLink
                        to="pricing"
                        smooth={true}
                        duration={500}
                        onClick={() => setIsSheetOpen(false)}
                      >
                        <Button variant="ghost" size="xl">
                          {translations.header.navigation.pricing}
                        </Button>
                        <br />
                      </ScrollLink>
                    ) : (
                      <RouterLink
                        to="/#pricing"
                        onClick={() => setIsSheetOpen(false)}
                      >
                        <Button variant="ghost" size="xl">
                          {translations.header.navigation.pricing}
                        </Button>
                        <br />
                      </RouterLink>
                    )}
                    {location.pathname === "/" ? (
                      <ScrollLink
                        to="services"
                        smooth={true}
                        duration={500}
                        onClick={() => setIsSheetOpen(false)}
                      >
                        <Button variant="ghost" size="xl">
                          {translations.header.navigation.samples}
                        </Button>
                        <br />
                      </ScrollLink>
                    ) : (
                      <RouterLink
                        to="/#services"
                        onClick={() => setIsSheetOpen(false)}
                      >
                        <Button variant="ghost" size="xl">
                          {translations.header.navigation.samples}
                        </Button>
                        <br />
                      </RouterLink>
                    )}
                    <RouterLink
                      to="/graphic-preparation-site"
                      onClick={() => setIsSheetOpen(false)}
                    >
                      <Button
                        variant="ghost"
                        size="xl"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {translations.header.navigation.graphicPreparation}
                      </Button>
                      <br />
                    </RouterLink>
                    {location.pathname === "/" ? (
                      <ScrollLink
                        to="faq"
                        smooth={true}
                        duration={500}
                        onClick={() => setIsSheetOpen(false)}
                      >
                        <Button variant="ghost" size="xl">
                          {translations.header.navigation.faq}
                        </Button>
                        <br />
                      </ScrollLink>
                    ) : (
                      <RouterLink
                        to="/#faq"
                        onClick={() => setIsSheetOpen(false)}
                      >
                        <Button variant="ghost" size="xl">
                          {translations.header.navigation.faq}
                        </Button>
                        <br />
                      </RouterLink>
                    )}
                    <RouterLink
                      to="/contact"
                      onClick={() => {
                        setIsSheetOpen(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <Button variant="ghost" size="xl">
                        {translations.header.navigation.contact}
                      </Button>
                      <br />
                    </RouterLink>
                    <RouterLink to="/order">
                      <Button
                        variant="default"
                        size="default"
                        className="uppercase mt-5"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          setIsSheetOpen(false);
                        }}
                      >
                        {translations.header.orderButton}
                      </Button>
                    </RouterLink>
                  </div>
                </SheetContent>
              </Sheet>
            </div>
          </div>
        </div>
      ) : (
        <div
          id="sticky-header"
          className="navbar relative z-50 w-full bg-white"
          ref={navbarRef}
        >
          <div className="flex justify-center items-center ">
            <div className="flex w-full justify-center items-center max-w-[1440px] px-10">
              <RouterLink to="/" className="min-w-[146px]">
                <img
                  src={logo}
                  alt="Logo"
                  style={{ float: "left" }}
                  onClick={() => scroll.scrollToTop()}
                />
              </RouterLink>
              <div className="flex w-full justify-center items-center p-5 xl:px-0">
                <NavigationMenu>
                  <NavigationMenuList className="flex justify-between items-center">
                    <NavigationMenuItem>
                      {location.pathname === "/" ? (
                        <ScrollLink
                          to="pricing"
                          smooth={true}
                          duration={500}
                          offset={-88}
                        >
                          <Button variant="ghost" size="default">
                            {translations.header.navigation.pricing}
                          </Button>
                        </ScrollLink>
                      ) : (
                        <RouterLink to="/#pricing">
                          <Button variant="ghost" size="default">
                            {translations.header.navigation.pricing}
                          </Button>
                        </RouterLink>
                      )}
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      {location.pathname === "/" ? (
                        <ScrollLink
                          to="services"
                          smooth={true}
                          duration={500}
                          offset={-88}
                        >
                          <Button variant="ghost" size="default">
                            {translations.header.navigation.samples}
                          </Button>
                        </ScrollLink>
                      ) : (
                        <RouterLink to="/#services">
                          <Button variant="ghost" size="default">
                            {translations.header.navigation.samples}
                          </Button>
                        </RouterLink>
                      )}
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <RouterLink to="/graphic-preparation-site ">
                        <Button
                          variant="ghost"
                          size="default"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {translations.header.navigation.graphicPreparation}
                        </Button>
                      </RouterLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      {location.pathname === "/" ? (
                        <ScrollLink
                          to="faq"
                          smooth={true}
                          duration={500}
                          offset={-88}
                        >
                          <Button variant="ghost" size="default">
                            {translations.header.navigation.faq}
                          </Button>
                        </ScrollLink>
                      ) : (
                        <RouterLink to="/#faq">
                          <Button variant="ghost" size="default">
                            {translations.header.navigation.faq}
                          </Button>
                        </RouterLink>
                      )}
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <RouterLink to="/contact">
                        <Button
                          variant="ghost"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {translations.header.navigation.contact}
                        </Button>
                      </RouterLink>
                    </NavigationMenuItem>
                  </NavigationMenuList>
                </NavigationMenu>
              </div>
              <RouterLink to="/order">
                <div className={orderPage ? "cursor-default" : ""}>
                  <Button
                    variant={orderPage ? "invisible" : "default"}
                    size="default"
                    className="uppercase"
                    onClick={() => window.scrollTo(0, 0)}
                    disabled={orderPage}
                  >
                    {translations.header.orderButton}
                  </Button>
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
