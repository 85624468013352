import { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "../ui/button";
import pin from "../../assets/png/pin.png";
import graphic_preparation_img from "../../assets/png/graphic_preparation_img.png";
import { useTranslation } from "../../TranslationContext";

const GraphicPreparation: FunctionComponent = () => {
  const translations = useTranslation();
  return (
    <div
      id="graphic_preparation"
      className="relative bg-white w-full overflow-hidden flex flex-col py-24 px-24 box-border items-center justify-start text-center text-4xl text-slate-900 font-normal md:px-12 sm:px-8 xs:px-4"
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-10">
        <div className="w-full flex flex-col items-center justify-start gap-8 max-w-[800px]">
          <div className="w-full flex flex-col items-center justify-start gap-4 max-w-[800px]">
            <div className="self-stretch relative leading-[150%] font-extrabold">
              {translations.graphicPreparation.title}
            </div>
            <div className="self-stretch relative text-lg sm:text-base leading-[150%] text-slate-500">
              {translations.graphicPreparation.description}
            </div>
          </div>
          <div className="relative w-fit flex flex-col items-start justify-center gap-2 text-left text-xl sm:text-lg xs:text-base text-slate-950 font-leading-tight font-normal pt-4">
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
              <b className="w-5 relative leading-[150%] flex text-gradient">
                {translations.graphicPreparation.steps[0].number}
              </b>
              <div className="flex flex-col items-start justify-start text-slate-950">
                <div className="relative leading-[150%] font-medium">
                  {translations.graphicPreparation.steps[0].text}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
              <b className="w-5 relative leading-[150%] flex text-gradient">
                {translations.graphicPreparation.steps[1].number}
              </b>
              <div className="flex flex-col items-start justify-start text-slate-950">
                <div className="relative leading-[150%] font-medium">
                  {translations.graphicPreparation.steps[1].text}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
              <b className="w-5 relative leading-[150%] flex text-gradient">
                {translations.graphicPreparation.steps[2].number}
              </b>
              <div className="flex flex-col items-start justify-start text-slate-950">
                <div className="relative leading-[150%] font-medium">
                  {translations.graphicPreparation.steps[2].text}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
              <b className="w-5 relative leading-[150%] flex text-gradient">
                ...
              </b>
              <div />
            </div>
          </div>
          <RouterLink to="/graphic-preparation-site">
            <Button
              variant="default"
              size="xl"
              className="uppercase "
              onClick={() => window.scrollTo(0, 0)}
            >
              {translations.graphicPreparation.buttonText}
            </Button>
          </RouterLink>
        </div>
      </div>
    </div>
  );
};

export default GraphicPreparation;
