import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import ReactGA from "react-ga4";
import Cookies from "js-cookie"; // Assuming you're using js-cookie for cookie management

import TableRow from "../ui/custom/tablerow";
import pin from "../../assets/png/pin.png";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Slider } from "../ui/slider";
import { useTranslation } from "../../TranslationContext";

const Pricing: FunctionComponent = () => {
  const translations = useTranslation();
  const [numberOfPieces, setNumberOfPieces] = useState<number | "">(6);
  const [graphicWork, setGraphicWork] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [piecePrice, setPiecePrice] = useState(0);

  const logMin = Math.log(0.2);
  const logMax = Math.log(200);
  const scale = logMax - logMin; // scale is now the difference between logMax and logMin

  useEffect(() => {
    setTotalPrice(0);
    setPiecePrice(0);
    let pricePerPiece = 0;
    let totalPrice = 0;
    if (typeof numberOfPieces === "number") {
      const pricingTable = [
        { min: 0.2, max: 0.49, price: 25 },
        { min: 0.5, max: 0.99, price: 20 },
        { min: 1, max: 1.49, price: 18 },
        { min: 1.5, max: 1.99, price: 17.5 },
        { min: 2, max: 3.99, price: 17 },
        { min: 4, max: 5.99, price: 16 },
        { min: 6, max: 8.99, price: 15 },
        { min: 9, max: 12.99, price: 14 },
        { min: 13, max: 19.99, price: 13 },
        { min: 20, max: 29.99, price: 12 },
        { min: 30, max: 99.99, price: 11 },
        { min: 100, max: 200, price: 10 },
        { min: 200.01, max: 201, price: 8 },
      ]; // Get the pricing array for the selected badge size

      // Find the two price points that numberOfPieces falls between
      let lowerPricePoint, upperPricePoint;
      for (let i = 0; i < pricingTable.length; i++) {
        if (
          numberOfPieces >= pricingTable[i].min &&
          numberOfPieces <= pricingTable[i].max
        ) {
          lowerPricePoint = pricingTable[i];
          upperPricePoint = pricingTable[i + 1];
          break;
        }
      }

      // Perform linear interpolation between the two price points
      if (lowerPricePoint && upperPricePoint) {
        const range = lowerPricePoint.max - lowerPricePoint.min;
        const progress = lowerPricePoint.max - numberOfPieces;
        const priceDifference = lowerPricePoint.price - upperPricePoint.price;
        pricePerPiece =
          Math.ceil(
            (upperPricePoint.price + (priceDifference / range) * progress) * 100
          ) / 100;

        totalPrice = Math.ceil(pricePerPiece * numberOfPieces * 100) / 100;
        if (shipping) {
          const shippingPrice = parseFloat(
            translations.pricing.shipping.price.replace(/[^0-9.-]+/g, "")
          );
          totalPrice += shippingPrice;
        }
        if (graphicWork) {
          totalPrice += 3;
        }
        setTotalPrice(parseFloat(totalPrice.toFixed(2)));
        setPiecePrice(parseFloat(pricePerPiece.toFixed(2)));
      }
      // Perform calculations
    }
  }, [numberOfPieces, graphicWork, shipping]);

  return (
    <div
      id="pricing"
      className="relative bg-white w-full flex flex-col py-16 px-10 box-border items-center justify-start text-center text-4xl text-slate-900 font-text-lg-font-normal md:px-10 sm:py-14 xs:px-4 xs:text-3xl"
    >
      <div className="w-full flex flex-col items-center justify-center gap-[48px] max-w-[1080px] pt-6">
        <div className="self-stretch flex flex-col items-center justify-center">
          <div className="self-stretch relative leading-[150%] font-extrabold xs:text-3xl">
            <h1>{translations.pricing.title}</h1>
          </div>
        </div>
        <div className="relative self-stretch flex flex-row lg:flex-col items-center justify-center gap-10 text-xl xs:text-lg text-slate-500">
          <div className="flex flex-col items-center justify-center py-6 px-0 gap-10 lg:gap-6 w-full max-w-[550px] xs:max-w-[350px]">
            <div className="self-stretch h-[109px] flex flex-row items-start justify-start gap-[16px] sm:gap-2">
              <b className="relative leading-[150%] text-gradient">1.</b>
              <div className="flex-1 flex flex-col items-start justify-start gap-[24px] text-lg xs:text-sm text-slate-600">
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="shrink-0 flex flex-col items-start justify-start">
                    <div className="relative leading-[150%] font-medium">
                      {translations.pricing.meters.label}
                    </div>
                    <div className="relative text-base xs:text-xs leading-[150%] text-slate-500">
                      {translations.pricing.meters.description}
                    </div>
                  </div>
                  <Input
                    type="number"
                    placeholder="0"
                    centered={true}
                    className="w-[80px] h-[48px] px-2 bg-white border-slate-400 font-medium"
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                    onValueChange={(value) => {
                      if (value === "") {
                        setNumberOfPieces("");
                      } else {
                        const numValue = Number(value);
                        if (numValue <= 20000) {
                          setNumberOfPieces(numValue);
                        }
                      }
                    }}
                    onBlur={() => {
                      // This function is called when the user stops dragging the slider
                      const cookieValue = Cookies.get("cookies-preference");
                      let analyticsConsent = false;

                      if (cookieValue) {
                        try {
                          const preferences = JSON.parse(cookieValue);
                          analyticsConsent = preferences.analytical === true; // Note the strict equality to boolean true
                        } catch (error) {
                          console.error(
                            "Error parsing cookies-preference:",
                            error
                          );
                        }
                      }
                      console.log(cookieValue);
                      console.log(analyticsConsent);
                      if (analyticsConsent) {
                        const analyticsValue =
                          typeof numberOfPieces === "number"
                            ? numberOfPieces
                            : 0;
                        ReactGA.event({
                          category: "Pricing Calculator",
                          action: "Text Input Blur",
                          label: "NumberOfPieces",
                          value: analyticsValue, // Assuming numberOfPieces is the value you want to track
                          nonInteraction: false, // Since this is a user interaction
                          transport: "xhr",
                        });
                      }
                    }}
                    value={numberOfPieces}
                    showCloseIcon={false}
                    min={20}
                    max={20000}
                  />
                </div>
                <div className="self-stretch flex flex-col items-center justify-center gap-[12px] text-sm text-slate-500">
                  <div className="self-stretch relative rounded-xl bg-slate-200 h-2">
                    <Slider
                      min={0}
                      max={1}
                      step={0.01}
                      value={[
                        typeof numberOfPieces === "number"
                          ? (Math.log(numberOfPieces) - logMin) /
                            (logMax - logMin)
                          : 0,
                      ]}
                      onValueChange={(values) => {
                        const val = Math.exp(logMin + scale * values[0]);
                        setNumberOfPieces(Math.round(val * 100) / 100);
                      }}
                      onValueCommit={() => {
                        // This function is called when the user stops dragging the slider
                        const cookieValue = Cookies.get("cookies-preference");
                        let analyticsConsent = false;

                        if (cookieValue) {
                          try {
                            const preferences = JSON.parse(cookieValue);
                            analyticsConsent = preferences.analytical === true; // Note the strict equality to boolean true
                          } catch (error) {
                            console.error(
                              "Error parsing cookies-preference:",
                              error
                            );
                          }
                        }
                        console.log(cookieValue);
                        console.log(analyticsConsent);
                        if (analyticsConsent) {
                          const analyticsValue =
                            typeof numberOfPieces === "number"
                              ? numberOfPieces
                              : 0;
                          ReactGA.event({
                            category: "Pricing Calculator",
                            action: "Slider Released",
                            label: "NumberOfPieces",
                            value: analyticsValue, // Assuming numberOfPieces is the value you want to track
                            nonInteraction: false, // Since this is a user interaction
                            transport: "xhr",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-between">
                    <div className="relative leading-[150%]">0.2</div>
                    <div className="relative leading-[150%]">6</div>
                    <div className="relative leading-[150%]">200</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[16px] sm:gap-2 text-slate-600">
              <b className="relative leading-[150%] text-gradient">2.</b>
              <div className="flex-1 shrink-0 flex flex-row items-center justify-between text-lg xs:text-sm">
                <div className="shrink-0 flex flex-col items-start justify-start">
                  <div className="relative leading-[150%] font-medium">
                    {translations.pricing.shipping.label}
                  </div>
                  <div className="relative text-base xs:text-xs leading-[150%] text-slate-500">
                    {translations.pricing.shipping.price}
                  </div>
                  <div className="relative text-base xs:text-xs leading-[150%] text-slate-500">
                    {translations.pricing.shipping.pickup}
                  </div>
                </div>
                <div className="shrink-0 flex flex-row items-start justify-start gap-[8px] text-sm text-slate-500">
                  <Button
                    variant={shipping ? "outline" : "outline_ghost"}
                    size="sm"
                    className="w-[70px] sm:w-[55px]"
                    onClick={() => setShipping(true)}
                  >
                    {translations.pricing.shipping.yes}
                  </Button>
                  <Button
                    variant={!shipping ? "outline" : "outline_ghost"}
                    size="sm"
                    className="w-[70px] sm:w-[55px]"
                    onClick={() => setShipping(false)}
                  >
                    {translations.pricing.shipping.no}
                  </Button>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[16px] sm:gap-2 text-slate-600">
              <b className="relative leading-[150%] text-gradient">3.</b>
              <div className="flex-1 shrink-0 flex flex-row items-center justify-between text-lg xs:text-sm">
                <div className="shrink-0 flex flex-col items-start justify-start">
                  <div className="relative leading-[150%] font-medium">
                    {translations.pricing.graphicWork.label}
                  </div>
                  <div className="relative text-base xs:text-xs leading-[150%] text-slate-500">
                    {translations.pricing.graphicWork.description}
                  </div>
                </div>
                <div className="shrink-0 flex flex-row items-start justify-start gap-[8px] text-sm text-slate-500">
                  <Button
                    variant={graphicWork ? "outline" : "outline_ghost"}
                    size="sm"
                    className="w-[70px] sm:w-[55px]"
                    onClick={() => setGraphicWork(true)}
                  >
                    {translations.pricing.graphicWork.yes}
                  </Button>
                  <Button
                    variant={!graphicWork ? "outline" : "outline_ghost"}
                    size="sm"
                    className="w-[70px] sm:w-[55px]"
                    onClick={() => setGraphicWork(false)}
                  >
                    {translations.pricing.graphicWork.no}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-2xl bg-white overflow-hidden flex flex-col items-center justify-center py-8 px-16 gap-5 text-base text-slate-600 border-[2px] border-solid border-fuchsia-500 min-w-[370px] sm:min-w-[250px] max-w-[400px] w-full">
            <div className="relative leading-[150%] uppercase font-medium text-xl">
              {translations.pricing.result.title}
            </div>
            <div className="shrink-0 flex flex-col items-center justify-center text-5xl text-slate-500">
              <div className="shrink-0 flex flex-col items-center justify-start">
                <b className="relative text-gradient">
                  {graphicWork ? translations.pricing.result.from : ""}
                  {totalPrice}€
                </b>
                <div className="self-stretch flex flex-row items-center justify-center text-2xl text-slate-600">
                  <div className="relative ">
                    {`${piecePrice.toFixed(2)}`}
                    {translations.pricing.result.perMeter}
                  </div>
                </div>
              </div>
              <div className="relative text-base leading-[150%]">
                {translations.pricing.result.withoutVAT}
              </div>
            </div>
            <div className="shrink-0 flex flex-col items-center justify-center text-white pt-5">
              <RouterLink to="/order">
                <Button
                  variant="default"
                  size="xl"
                  className="uppercase w-[180px]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.pricing.result.orderButton}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
