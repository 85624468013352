import { FunctionComponent } from "react";
import { useTranslation } from "../../TranslationContext";

import delivery from "../../assets/svg/delivery.svg";
import fast from "../../assets/svg/fast.svg";
import price_tag from "../../assets/svg/price_tag.svg";
import stars from "../../assets/svg/stars.svg";

const Features: FunctionComponent = () => {
  const translations = useTranslation();
  return (
    <div
      id="features"
      className="relative bg-white w-full overflow-hidden flex flex-col py-14 px-24 box-border items-center justify-start text-center text-xl text-slate-900 font-text-base-font-normal lg:px-16 sm:px-10 xs:px-8"
    >
      <div className="grid grid-cols-4 lg:grid-cols-2 xs:grid-cols-1 items-start justify-start gap-10 max-w-[1400px]">
        <div className="flex-1 flex flex-col items-center justify-start gap-[16px]">
          <img className="relative w-16 h-16" alt="" src={price_tag} />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <b className="self-stretch relative leading-[125%]">
              {translations.features.price.title}
            </b>
            <div className="self-stretch relative text-base leading-[150%] text-slate-700">
              {translations.features.price.description1}{" "}
              <span className="font-semibold text-slate-900">
                {translations.features.price.highlight}
              </span>
              {translations.features.price.description2}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center justify-start gap-[16px]">
          <img className="relative w-16 h-16" alt="" src={stars} />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <b className="self-stretch relative leading-[125%]">
              {translations.features.quality.title}
            </b>
            <div className="self-stretch relative text-base leading-[150%] text-slate-700">
              {translations.features.quality.description}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center justify-start gap-[16px]">
          <img className="relative w-16 h-16" alt="" src={fast} />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <b className="self-stretch relative leading-[125%]">
              {translations.features.expressProcessing.title}
            </b>
            <div className="self-stretch relative text-base leading-[150%] text-slate-700">
              {translations.features.expressProcessing.description1}{" "}
              <span className="font-semibold text-slate-900">
                {translations.features.expressProcessing.highlight}
              </span>
              {translations.features.expressProcessing.description2}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center justify-start gap-[16px]">
          <img className="relative w-16 h-16" alt="" src={delivery} />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <b className="self-stretch relative leading-[125%]">
              {translations.features.fastDelivery.title}
            </b>
            <div className="self-stretch relative text-base leading-[150%] text-slate-700">
              {translations.features.fastDelivery.description1}{" "}
              <span className="font-semibold text-slate-900">
                {translations.features.fastDelivery.highlight}
              </span>
              {translations.features.fastDelivery.description2}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
