import { FunctionComponent } from "react";

import shirt from "../../assets/svg/shirt.svg";
import hoodie from "../../assets/svg/hoodie.svg";
import cap from "../../assets/svg/baseball-cap.svg";
import bag from "../../assets/svg/bag.svg";
import backpack from "../../assets/svg/backpack.svg";
import jersey from "../../assets/svg/jersey.svg";
import { useTranslation } from "../../TranslationContext";

const Services: FunctionComponent = () => {
  const translations = useTranslation();
  return (
    <div
      id="services"
      className="relative bg-slate-950 w-full flex flex-col p-24 pb-0 box-border items-center justify-start gap-14 text-center text-4xl text-white font-text-sm-font-medium lg:px-6 md:py-10 xs:px-4 md:pb-4 xs:text-3xl"
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-[24px]">
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
          <div className="self-stretch relative leading-[150%] font-extrabold">
            <h1>{translations.services.title}</h1>
          </div>
          <div className="self-stretch relative text-xl leading-[150%] text-slate-200 lg:px-16 md:px-4 sm:px-0 xs:text-base">
          {translations.services.description}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-between gap-4 max-w-[1100px] text-xl text-slate-200 md:flex-col md:gap-10 xs:gap-6 lg:text-lg xs:text-sm">
        <div className="w-full flex-1 flex flex-row items-center justify-between gap-2 lg:gap-0">
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.altTexts.shirts}
              src={shirt}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">{translations.services.items.shirts}</div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.altTexts.hoodies}
              src={hoodie}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">{translations.services.items.hoodies}</div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img className="relative w-16 h-16" alt={translations.services.altTexts.bags} src={bag} />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">{translations.services.items.bags}</div>
            </div>
          </div>
        </div>
        <div className="w-full flex-1 flex flex-row items-center justify-between gap-2 xs:gap-0">
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.altTexts.backpacks}
              src={backpack}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">{translations.services.items.backpacks}</div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.altTexts.caps}
              src={cap}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">{translations.services.items.caps}</div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[12px]">
            <img
              className="relative w-16 h-16"
              alt={translations.services.altTexts.jerseys}
              src={jersey}
            />
            <div className="flex flex-row items-center justify-center">
              <div className="relative leading-[150%]">{translations.services.items.jerseys}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
